import React, { Component } from 'react';
import '../AppCalendar.css';

import  moment from  'moment/moment';
import Year from "./Year";


class YearList extends Component {
    constructor(props) {
        super(props)
        var year = this.props.year;
        var firstDay = moment().startOf("month").format("d");
        this.state = {
            'year' : year,
            'firstDay' : firstDay,
            'years' : [2001,2002,2003,2004,2005,2006,2007,2008,2009,2010,2011,2012,2013,2014,2015,2016,2017,2018,2019,2020,2021,2022,2023,2024,2025],
        }
    }
    componentDidMount() {
        this.scroll()
    }

    componentDidUpdate() {
       this.scroll()
    }

    scroll() {
        const  id = this.state.year;

        if (!id) {
            return
        }
        const element = document.getElementById(id)
        console.log(id);
        if (element) {
            element.scrollIntoView({ block: "center", inline: "nearest"});
            console.log('dde');
        }
    }

    createTable = () => {
        let table = []

        // Outer loop to create parent
        for (let i = 0; i < 3; i++) {
            let children = []
            //Inner loop to create children
            for (let j = 0; j < 5; j++) {
                children.push(<td>{`Column ${j + 1}`}</td>)
            }
            //Create the parent and add the children
            table.push(<tr>{children}</tr>)
        }
        return table
    }    
    goToCurrentYear () {
        let elementId = new Date().getFullYear();
        const element = document.getElementById(elementId);
        if (element) {
            // element.scrollIntoView({behavior: 'smooth'});
            element.scrollIntoView({block: "center", inline: "nearest"});
        }
    }
    

    render() {    
        let checkDev = this.props.mblDevice;
        return (

            <div className="App">
                <div>{this.state.year}</div>
                <div>{this.state.firstDay}</div>
                <table>
                    {/*{this.createTable()}*/}
                </table>

                <div  className= {`pointer ${checkDev ? 'mblYear-tab' : 'year-tab'}`}  style={{textAlign:'right'}}  >
                    <span className = "col-sm-2" style={{color:'red' , 
                        marginTop:'22px'}} onClick={() => this.goToCurrentYear(this)}>Current Year
                    </span>
               </div>
                {this.state.years.map((item, key) =>
                    <Year year = {item} id={item} yearDetail={this.props.yearDetail}
                    createdEvents={this.props.createdEvents}
                    startAt= {this.props.startAt} endAt = {this.props.endAt}
                    mblDevice = {this.props.mblDevice}/>
                )}

            </div>
        );
    }

}


export default YearList;