import React from 'react';
import  moment from  'moment'
import  PopulateData from '../populate';
export class DisplayAgenda extends React.Component{

 

    constructor(props){
        super(props);

        this.state = {
            
            startAt : '',
            endAt : '',
            title : '',
            location : '',
            eveDate : '',
            today :  '',
            isAllDay : '',
            // totalEveDays : this.props.createdEvent.endAt.diff
            }

            this.assignValue = this.assignValue.bind(this);

            console.log("days1 createdEvent:", this.props.createdEvent);
            console.log("days1 startAt:", this.props.createdEvent.startAt);
            console.log("days1 endAt:", this.props.createdEvent.endAt);
    }
    
      assignValue(){

        console.log("this.props.createdEvent.startAt", this.props.createdEvent.startAt);
        console.log("this.props.createdEvent.endAt", this.props.createdEvent.endAt);

        if(this.props.createdEvent.startAt)
            this.state.startAt = moment(this.props.createdEvent.startAt).format('hh:mm A');
        if(this.props.createdEvent.endAt)
            this.state.endAt = moment(this.props.createdEvent.endAt).format('hh:mm A');
        this.state.title = this.props.createdEvent.title;
        this.state.location= this.props.createdEvent.location;
        this.state.eveDate = moment(this.props.createdEvent.eventDate).format('ddd MMM D');
        this.state.today = moment().format('ddd MMM D');
        this.state.isAllDay = this.props.createdEvent.isAllDay;
        
    }
      
  handleShowSignInDialog = (props) => {
    this.props.handleShowSignInDialog(this.props);
  }
    render(){
     this.assignValue();
     const {auth} = this.props;
 
        return(
           
            // Mon Feb 5
            <div className="">
            <PopulateData createdEvent={this.state} 
                actualEvent={this.props.createdEvent}
                auth={auth}
                OAuthSignIn={this.props.OAuthSignIn}
                handleShowSignInDialog={this.handleShowSignInDialog}
                displayHeader = {this.props.displayHeader}
             startStamp={this.props.createdEvent.eventDate}
             eventStartAt={this.props.createdEvent.startAt}
             eventEndAt={this.props.createdEvent.endAt}
             currentCal={this.props.currentCal}
             fullScreen={this.props.fullScreen} events={this.props.events}/>
           
   
            </div>
        );
        }
    }



