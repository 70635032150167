import React, { Component } from 'react'
import _ from 'lodash'
// import { Formik } from "formik"
// import {UpdateEventForm, updateEventSchema} from "./updateEventForm.js"
// import {CreateEventForm, createEventSchema} from "./createEventForm.js"
import {toDate, format} from 'date-fns'
import Event from './event.js'
import Test from '../test'
import TagChip from './tagChip.js'
import UpdateCal from './updateCal.js'
import Button  from "@material-ui/core/Button"
import Dialog  from "@material-ui/core/Dialog"
import DialogTitle  from "@material-ui/core/DialogTitle"
import DialogContent  from "@material-ui/core/DialogContent"
//import DialogContentText  from "@material-ui/core/DialogContentText"
import DialogActions  from "@material-ui/core/DialogActions"
import SnackbarContent  from "@material-ui/core/SnackbarContent"
import withMobileDialog from '@material-ui/core/withMobileDialog'
//import Chip from '@material-ui/core/Chip'
// import Card from '@material-ui/core/Card'
// import CardHeader from '@material-ui/core/CardHeader'
//import CardMedia from '@material-ui/core/CardMedia'
// import CardContent from '@material-ui/core/CardContent'
// import CardActions from '@material-ui/core/CardActions'
// import Collapse from '@material-ui/core/Collapse'
// import Paper  from "@material-ui/core/Paper"
// import InputBase from '@material-ui/core/InputBase'
// import TextField from "@material-ui/core/TextField"
// import FormControlLabel from "@material-ui/core/FormControlLabel"
// import Popover from '@material-ui/core/Popover'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import SaveIcon from '@material-ui/icons/CloudQueue'
//import SavedIcon from '@material-ui/icons/CloudDone'
import ShareIcon from '@material-ui/icons/Share'
import CopyIcon from '@material-ui/icons/Assignment'
import withStyles from "@material-ui/core/styles/withStyles"
import YearList from "../calendar/YearList";
import YearDetail from "../calendar/YearDetail";
import YearDetailCurrent from "../calendar/YearDetailCurrent";
import  moment from  'moment/moment';
import Day from "../calendar/Day";
import Agenda from "../calendar/Agenda";
//const {CAFE} = window

const styles = theme => ({
    snackbarContent: {
        background: theme.palette.primary.dark,
        margin: theme.spacing.unit
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    container: {
        maxWidth: "200px"
    },
    calendarTitle: {
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        clear: "both",
      },
    },
    tagsContainer: {
    },

    tagChip: {
      display: "inline-block",
      fontSize: "12px",
      lineHeight: "12px",
      borderRadius: "20px 5px 5px 20px",
      padding: "2px 2px 4px 2px",
      border: "1px solid #fff",
      borderColor: theme.palette.secondary.light,
      color: theme.palette.secondary.light,
      cursor: "pointer",
      marginRight: "2px",
    },
    tagChipSelected: {
      display: "inline-block",
      fontSize: "12px",
      lineHeight: "12px",
      borderRadius: "20px 5px 5px 20px",
      padding: "2px 2px 4px 2px",
      border: "1px solid #fff",
      borderColor: theme.palette.secondary.light,
      backgroundColor: theme.palette.secondary.light,
      color: "#fff",
      cursor: "pointer",
      marginRight: "2px",
    },
    tagIcon: {
      fontSize: "12px",
      lineHeight: "12px",
      top: ".05em",
      position: "relative",
      paddingRight: "2px"
    },
    actionsContainer: {
      float: "right",
    },
    eventsContainer: {
      marginTop: "20px"
    },
    headerToday: {
      color: "#fff",
      padding: "4px 10px",
      backgroundColor: theme.palette.alt1.main,
      maxWidth: "400px",
      margin: "8px",
      borderRadius: "20px",
      textAlign: "center",
    },
    headerYear: {
      fontSize: "18px",
      fontWeight: "bold",
      color: theme.palette.secondary.light,
      //borderBottom: "1px solid #ddd",
     // borderColor: theme.palette.secondary.light,
    },
    
    infoBox: {
      padding: "20px",
      marginTop: "0px",
      borderRadius: "20px",
      color: "#fff",
      backgroundColor: theme.palette.alt1.main,
    }
  
})

class Cal extends Component {

  constructor(props) {
    super(props)

    this.getUserAccessLevelKey = this.getUserAccessLevelKey.bind(this);
    this.state = {
      renderSignInDialog: false,
      currentEvent: false,
      addEventOpen: false,
      showUpdateCal: false,
      showAgendaView: true,
      scrollToCurMonth: false,
      showCalShare: false,
      showCalShareTarget: null,
      // selectedTags: ["Current Events"],
      selectedTags: [],
      yearDetail : false,
      yearDetailCurrent : false,
      yearList : false,
      yearDetailMonthName : "",
      yearDetailYear : null,
      showWeek : false,
      showWeekDate : moment().format('D-M-Y'),
      yearOnScreen :moment().year(),
      todayDate : moment().format('D-M-Y'),
    }

    this.goToCurrentMonth = this.goToCurrentMonth.bind(this);
  }

  componentWillMount = () => {
      this.setState({yearDetail: false})
  }
  componentDidMount = () => {
      this.setState({yearDetail: false})
  }
  handleSignInButton = () => {
      if(this.props.OAuthSignIn) this.props.OAuthSignIn()
  }
  handleSelectTag = (tag) => {
    let selectedTags = this.state.selectedTags
    const pos = selectedTags.indexOf(tag)
    if(pos > -1) selectedTags.splice(pos,1)
    else selectedTags.push(tag)
    this.setState({selectedTags: selectedTags})
  }
  removeCurrentEventsFlag(){
    let selectedTags = this.state.selectedTags
    const pos = selectedTags.indexOf("Current Events")
    if(pos > -1) selectedTags.splice(pos,1)
    this.setState({selectedTags: selectedTags})
  }

  handleShowUpdateCal = () => {
    this.setState({showUpdateCal: true})
  }  
  handleHideUpdateCal = () => {
    this.setState({showUpdateCal: false})
  }  
  handleShowAddEvent = () => {
      console.log('add event');
    this.setState({addEventOpen: true})
  }  
  handleHideAddEvent = () => {
    this.setState({addEventOpen: false})
  }
   yearDetail = (e) => {
        console.log('go to year detail 3 yearDetail');
       console.log(e.month,e.year);
       this.setState({
         showAgendaView: false,
         scrollToCurMonth: false
        });
       this.setState({yearList: false});
       this.setState({yearDetail: true})
       const year = e.year;
       var monthName =moment().month(e.month).format("MMM");
       console.log('monthname :', monthName);
       console.log('year :', year);
       this.setState({yearDetailMonthName: monthName});
       this.setState({yearDetailYear: year});
       console.log(this.state.yearDetailMonthName);

       var url = window.location.href;
       console.log('href url:', url);
       if (url.includes('&v=yearly')){
         url = url.replace('&v=yearly', '')
       }
       if (url.includes('?v=yearly')){
         url = url.replace('?v=yearly', '')
       }
       if (url.indexOf('?') > -1){
         url += '&v=monthly'
       }else{
         url += '?v=monthly'
       }
       window.history.replaceState(null, null, url);

  }
  handleShowSignInDialog = (props) => {
    this.setState({
      renderSignInDialog: true
    });
  }

  handleHideSignInDialog = (props) => {
    this.setState({
      renderSignInDialog: false
    });
  }
    goToYearDetail = (props) => {
        console.log('go to year detail4 goToYearDetail',props.date);
        console.log('go to year detail showDate',props.showDate);
        // console.log(props.date);
        var split = props.showDate.split("-");
        var monthName = moment(split[1], 'M').format('MMM');
        var year = moment(split[2], 'Y').format('Y');
        console.log('monthname :', monthName);
        console.log('year :', year);
        console.log('before:',this.state.yearDetailMonthName);
        this.setState({
          yearDetailMonthName : monthName,
          yearDetailYear: year
        });
        console.log('after :' ,this.state.yearDetailMonthName);
        console.log('after Year :' ,this.state.yearDetailYear);
        this.setState({yearDetail: true});

        this.setState({addEventOpen: false});
        this.setState({showAgendaView: false, scrollToCurMonth: false});
        this.setState({showWeek: false});

        var url = window.location.href;
        console.log('href url:', url);
        if (url.includes('&v=yearly')){
          url = url.replace('&v=yearly', '')
        }
        if (url.includes('?v=yearly')){
          url = url.replace('?v=yearly', '')
        }
        if (url.indexOf('?') > -1){
          url += '&v=monthly'
        }else{
          url += '?v=monthly'
        }
        window.history.replaceState(null, null, url);

    }

    goToYearList = (props) => {
        console.log('go to year detail goToYearList');
        console.log(this.state.yearDetailMonthName);
        console.log(props);
        this.setState({yearDetail: false});
        this.setState({yearOnScreen: props.year});
        this.setState({addEventOpen: false});
        this.setState({yearList: true});
        this.setState({showWeek: false});
        this.removeCurrentEventsFlag();

        var url = window.location.href;
        console.log('href url:', url);
        if (url.includes('&v=monthly')){
          url = url.replace('&v=monthly', '')
        }
        if (url.includes('?v=monthly')){
          url = url.replace('?v=monthly', '')
        }

        if (url.indexOf('?') > -1){
          url += '&v=yearly'
        }else{
          url += '?v=yearly'
        }
        window.history.replaceState(null, null, url);

    }

    goToCurrentMonth () {

      // console.log(props);
      // console.log('go to current montheded');
      console.log('go to year detail go to vvjjgj',this.state.yearDetailYear);
      console.log('go to vvjjgj yearDetail',this.state.yearDetail);

      moment().date(  )
      const year = moment().year();
      const monthName = moment().month( moment().month()).format('MMM');
      this.setState({
        yearDetailYear: year,
        yearDetailMonthName: monthName,
        scrollToCurMonth: true
      });  
      console.log('monthname :', monthName);
      console.log('year :', year);
      console.log('go to yeardetail',this.state.yearDetailYear);
      // this.setState({yearDetail: false});
      this.setState({addEventOpen: false});
      this.setState({showAgendaView: false});
      this.setState({showWeek: false});
      // this.setState({yearDetailCurrent: true});
        
  }

    
  showWeek = (date) => {
      console.log(date);
      console.log('i am here');
      this.setState({showWeekDate: date});
      this.setState({showWeek: true});
      this.setState({showAgendaView: true, scrollToCurMonth: false});
      this.setState({yearDetail: false});
      this.setState({addEventOpen: false});
       
       var url = window.location.href;
       console.log('href url:', url);
       if (url.includes('&v=yearly')){
         url = url.replace('&v=yearly', '')
       }
       if (url.includes('?v=yearly')){
         url = url.replace('?v=yearly', '')
       }
       if (url.includes('&v=monthly')){
         url = url.replace('&v=monthly', '')
       }
       if (url.includes('?v=monthly')){
         url = url.replace('?v=monthly', '')
       }
       window.history.replaceState(null, null, url);
  }
    handleShowCalShare = (event) => {
    const {showCalShare} = this.state
    this.setState({showCalShare: !showCalShare, showCalShareTarget: event.currentTarget})
  }  
  handleCopyToClipboard = (text) => {
    var dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.setAttribute('value', text);
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    alert(`Copied ${text} to clipboard!`)
  }

  isUserOwner(){
    const {auth, currentCal, savedCals} = this.props;
    const isUserSignedIn = auth && auth.account && auth.account.id;
    let isOwner = false;

    if(!isUserSignedIn) return isOwner;
    
    const adminKeys = _.filter(currentCal.accessKeys, key => key.accessType === "ADMIN")
    const calOwnerKeys = _.filter(currentCal.accessKeys, key => key.accessType === "OWNER")
    const urlhref = window.location.href;

    calOwnerKeys.map( accessKey => (
      isOwner = (urlhref.includes(accessKey.key)) ? true : false
    ));
    return isOwner; 
  }

  isUserAdmin(){
    const {auth, currentCal, savedCals} = this.props;
    const isUserSignedIn = auth && auth.account && auth.account.id;
    let isAdmin = false;

    if(!isUserSignedIn){ 
      return isAdmin;
    }
    
    const calAdminKeys = _.filter(currentCal.accessKeys, key => key.accessType === "ADMIN")
    const ownerKeys = _.filter(currentCal.accessKeys, key => key.accessType === "OWNER")
    const urlhref = window.location.href;

    calAdminKeys.map( adminKey => (
      isAdmin = (urlhref.includes(adminKey.key)) ? true : false
    ));
    return isAdmin; 
  }

  isUserViewer(){
    const {auth, currentCal, savedCals} = this.props;
    let isViewer = false;
    const urlhref = window.location.href;

    isViewer = urlhref.includes(currentCal.id);
    return isViewer; 
  }

  getUserAccessLevelKey(){
    const urlSearch = window.location.search;
    if(urlSearch.length && urlSearch.includes("?key=")){
      const keysArr = urlSearch.split("?key=");
      if(keysArr.length > 1 && keysArr[1].includes("&key=")){
        const mainAccessKey = keysArr[1].split("&key=");
        return mainAccessKey[0];  
      } else {
        return keysArr[1];  
      }
    }
    return window.location.pathname;;
  }

 checkDevice(){
  if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
  return true;
  }
  else{
    return false;
  }
 } 

  render = () => {
    const me = this
    const userAccessLevelKey = this.getUserAccessLevelKey();
    console.log("user Access LevelKey", userAccessLevelKey)
    //CAFE.log(`RENDERING: cal`, me.props)
    const {auth, currentCal, savedCals, classes, fullScreen} = me.props
    const isSignedIn = auth && auth.account && auth.account.id
    let isOwner = false
    let isAdmin = false
    
    if(!currentCal) return false
    
    const isSaved = _.some(savedCals, (savedCal) => savedCal.cal.id === currentCal.id)
    
    const adminKeys = _.filter(currentCal.accessKeys, key => key.accessType === "ADMIN")
    const ownerKeys = _.filter(currentCal.accessKeys, key => key.accessType === "OWNER")
    let urlhref = window.location.href;
    //Check for Owner Keys
    ownerKeys.map( accessKey => (
      isOwner = (urlhref.includes(accessKey.key)) ? true : false
    ));
    //Check for Admin Keys
    adminKeys.map( adminKey => (
      isAdmin = (urlhref.includes(adminKey.key)) ? true : false
    ));

    const showPastEvents = this.state.selectedTags.indexOf("Current Events") < 0
    let today = new Date()
    today = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0)
    let haveShownToday = false
    
    let tags = currentCal.tags ? currentCal.tags.slice(0) : []
    tags.unshift("Current Events")
    const activeTagsLowered = _.map(_.filter(this.state.selectedTags, tag=>tag!=="Current Events"), tag => tag.toLowerCase())

    const links = currentCal.eventLinks ? currentCal.eventLinks.items : []
    
    const events = _.orderBy(_.filter(_.map(links, (link) => {
      let event = link.event
      event.link = Object.assign({}, link)
      delete event.link.event
      return event
    }), eventToFilter => {
      if(!showPastEvents && toDate(Date.parse(eventToFilter.startAt)) < today) return false
      if(activeTagsLowered.length > 0 && (!eventToFilter.tags || eventToFilter.tags === 0 || !eventToFilter.tags.some( tag => activeTagsLowered.indexOf(tag.toLowerCase()) > -1 ))) return false 
      return true
    }), ['startAt', 'isAllDay'], ['asc', 'desc'])
    console.log("cal events.length:", events.length)
    
    if(!currentCal) return ( <SnackbarContent className={classes.snackbarContent}
          message={<span id="message-id">Unable to access selected calendar!</span>}
        /> )
  
    const siteUrl = process.env.SITE_URL || "https://mycafe.mobi"
  
    let lastYear = 0//, lastMonth = 0

    let checkDev = this.checkDevice();
    let calTitleClass = classes.calendarTitle;
    let tagsConClass = classes.tagsContainer;
    console.log('checkDevice',checkDev);



    const accountId = auth && auth.account ? auth.account.id : false
    let signInDialog = accountId ?
      ( <SnackbarContent className={classes.snackbarContent}
        message={<span id="message-id">You must be signed in to update event!</span>}
        action={[
          <Button key="signin" color="secondary" size="large" onClick={this.handleSignInButton}>
            Sign In or Sign Up
          </Button>
        ]}
      /> )
      :null;
      if(!this.state.renderSignInDialog) signInDialog = null;

      
    return (
      <div style={{touchAction: "manipulation"}} >
        
     <div className={checkDev ? 'mblMainHeader divBackgrnd' : 'mainHeader'}>
        { /*<Button style={{float: "right"}} color="secondary" className={classes.button} onClick={this.handleShowAddEvent}>Add Event</Button>*/ }
        <div className={`mainHeader ${classes.actionsContainer}`}  >
            {isSaved ? ( false
            ) : isSignedIn ? (
            <IconButton color="secondary" aria-label="Save Calendar" title="Save Calendar">
               <SaveIcon /> 
            </IconButton> 
            ) : (
            <IconButton aria-label="Must be signed in to Save Calendar" title="Must be signed in to Save Calendar">
               <SaveIcon /> 
            </IconButton> 
          )}
          {(!isAdmin && !isOwner) || (
            <span>
              <IconButton color="secondary" aria-label="Share Calendar" title="Share Calendar" onClick={this.handleShowCalShare}>
                <ShareIcon />
              </IconButton>
              <IconButton color="secondary" aria-label="Add Event" title="Add Event" onClick={this.handleShowAddEvent}>
                <AddIcon />
              </IconButton>
              {!isOwner || (<IconButton color="secondary" aria-label="Edit Calendar" title="Edit Calendar" onClick={this.handleShowUpdateCal}>
                <EditIcon />
              </IconButton>)}
            </span>
          )}
          </div>
        </div>
        <h1 className={checkDev ?'mblHeaderTitle  calTitleClass'
        :'headerTitle calTitleClass'} >{currentCal.title}</h1>
        <div className= {checkDev ? 'mblTagContainer tagsConClass' : 'tagContainer tagsConClass'} >
        {tags.map(tag => {
          const isTagSelected = this.state.selectedTags.indexOf(tag) > -1
          return (
            <TagChip  key={tag} selected={isTagSelected} tag={tag} handleSelectTag={this.handleSelectTag} />
            // <span key={tag} className={isTagSelected ? classes.tagChipSelected : classes.tagChip} onClick={this.handleSelectTag.bind(this, tag)}>
            //   {isTagSelected ? <CheckIcon className={classes.tagIcon} /> : <UncheckIcon className={classes.tagIcon} />}
            //   {tag}
            // </span>
          )})}
        </div>
       {signInDialog}
        <Dialog
          fullScreen={fullScreen}
          open={this.state.showCalShare}
          onClose={this.handleShowCalShare}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{`${currentCal.title} - Share Calendar `}</DialogTitle>
          <DialogContent>
            <div style={{padding: "10px 0", maxWidth: "400px", display: this.state.showCalShare ? "block" : "none"}}>
                <p className={classes.infoBox}>You can share this calendar with anyone... simply send them one of the following links!</p>
               <div>
                 <div style={{fontSize: "14px"}}>View only access:</div>
                 <div style={{display: "flex", flexDirection: "row"}}>
                   <div style={{flex: 1, fontSize: "12px", padding: "4px", margin: "10px 0", border:"1px solid #ddd", overflowX: "auto"}}>{`${siteUrl}/${currentCal.id}`}</div>
                   <IconButton color="secondary" aria-label="Copy to clipboard" title="Copy to clipboard" onClick={this.handleCopyToClipboard.bind(this, `${siteUrl}/${currentCal.id}`)}>
                      <CopyIcon />
                    </IconButton>
                  </div>
                 {!adminKeys.length ||  (
                 <div>
                   <div style={{fontSize: "14px"}}>Admin access (create, update, delete events):</div>
                   {adminKeys.map( accessKey => (
                     <div key={accessKey.key} style={{display: "flex", flexDirection: "row"}}>
                       <div style={{flex: 1, fontSize: "12px", padding: "4px", margin: "10px 0", border:"1px solid #ddd", overflowX: "auto"}}>{`${siteUrl}/${currentCal.id}?key=${accessKey.key}`}</div>
                       <IconButton color="secondary" aria-label="Copy to clipboard" title="Copy to clipboard" onClick={this.handleCopyToClipboard.bind(this, `${siteUrl}/${currentCal.id}?key=${accessKey.key}`)}>
                          <CopyIcon />
                        </IconButton>
                    </div>))}
                  </div>
                  )}
                 {!ownerKeys.length ||  (
                 <div>
                    <div style={{fontSize: "14px"}}>Owner access (only share this if you are sure!):</div>
                   {ownerKeys.map( accessKey => (
                    <div key={accessKey.key} style={{display: "flex", flexDirection: "row"}}>
                     <div style={{flex: 1, fontSize: "12px", padding: "4px", margin: "10px 0", border:"1px solid #ddd", overflowX: "auto"}}>{`${siteUrl}/${currentCal.id}?key=${accessKey.key}`}</div>
                     <IconButton color="secondary" aria-label="Copy to clipboard" title="Copy to clipboard" onClick={this.handleCopyToClipboard.bind(this, `${siteUrl}/${currentCal.id}?key=${accessKey.key}`)}>
                        <CopyIcon />
                      </IconButton>
                    </div>))}
                  </div>
                  )}
               </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleShowCalShare} color="primary" autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog> 
        
        {this.state.showUpdateCal && <UpdateCal  
                      auth={auth} 
                      OAuthSignIn={this.props.OAuthSignIn} 
                      fullScreen={fullScreen} 
                      currentCal={currentCal} 
                      handleClose={this.handleHideUpdateCal} />}
        
        <div className={classes.eventsContainer}>
          {events.length > 0 || <p style={{maxWidth: "400px"}} className={classes.infoBox}>
            {activeTagsLowered.length === 0 
              ? (showPastEvents ? "This calendar does not yet have any events for this month!" : "This calendar does not have any current events.")
              : `No events were found with any of the selected tags: ${_.join(this.state.selectedTags.slice(0).slice(1))}`}
              { /*<br/>{isAdmin ? "You should add some using the plus icon above!" : "" }*/ }
            </p>}
          {/* {events.map(event => {
            const dt = toDate(event.startAt)
            const showToday = !haveShownToday && dt >= today
            if(showToday) haveShownToday = true
            const showYearFrom = lastYear !== dt.getFullYear() ? (lastYear === 0 ? dt.getFullYear() : lastYear + 1) : false 
            //const showMonthFrom = lastMonth !== dt.getMonth() ? lastMonth + 1 : false
            lastYear = dt.getFullYear()
            //lastMonth = dt.getMonth()
             return (
                <div key={event.link.id}>
                  {showYearFrom ? <div className={classes.headerYear}>{showYearFrom}</div> : false}
                  {showToday ? <div className={classes.headerToday}>TODAY: {format(today, "PPPP")}</div> : false}
                  <Event 
                    auth={auth} 
                    event={event} 
                    fullScreen={fullScreen} 
                    currentCal={currentCal}  />
                </div>
            )
          })} */}
          {!this.state.addEventOpen || (
            <Event
              key="addevent"
              auth={auth}
              OAuthSignIn={this.props.OAuthSignIn} 
              event={{}}
              userAccessLevelKey = {userAccessLevelKey}
              fullScreen={fullScreen}
              currentCal={currentCal}
              addEventOpen={this.state.addEventOpen}
              handleHideAddEvent={this.handleHideAddEvent} 
            />
          )}
            {!this.state.yearList ||(
                <YearList
                    year = {this.state.yearOnScreen}
                    yearDetail={this.yearDetail}
                    createdEvents = {events}
                    goToCurrentYear= {this.goToCurrentYear}
                    mblDevice = {checkDev}
                />
            )}
            {!this.state.showAgendaView  ||(
                <Day
                    createdEvents = {events}
                    auth={auth}
                    OAuthSignIn={this.props.OAuthSignIn} 
                    handleShowSignInDialog={this.handleShowSignInDialog}
                    date = {this.state.todayDate}
                    goToYearDetail={this.goToYearDetail}
                    showDate = {this.state.showWeekDate}
                    currentCal={currentCal}
                    fullScreen={fullScreen}
                    events={events} 
                    mblDevice={checkDev}
                    
                />
            )}
            {!this.state.yearDetail || (
                <YearDetail
                    // key="addevent"
                    // auth={auth}
                    // event={{}}
                    // fullScreen={fullScreen}
                    // currentCal={currentCal}
                    // addEventOpen={this.state.addEventOpen}

                    year = {this.state.yearDetailYear}
                    scrollToCurMonth= {this.state.scrollToCurMonth}
                    calId= {currentCal.id}
                    monthName = {this.state.yearDetailMonthName}
                    showWeek={this.showWeek}
                    goToYearList = {this.goToYearList}
                    goToCurrentMonth = {this.goToCurrentMonth}
                    createdEvents = {events}
                    mblDevice = {checkDev}
                />
            )}

{!this.state.yearDetailCurrent || (
                <YearDetailCurrent
                    
                    year = {this.state.yearDetailYear}
                    monthName = {this.state.yearDetailMonthName}
                    showWeek={this.showWeek}
                    goToYearList = {this.goToYearList}
                    goToCurrentMonth = {this.goToCurrentMonth}
                    createdEvents = {events}
                    mblDevice = {checkDev}
                />
            )}

        </div>
      </div>
    );
  }
}


export default withMobileDialog()(withStyles(styles)(Cal))

