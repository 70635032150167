// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile1 =  {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:9d83ead4-65db-4cc3-9579-cde99bc86007",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_VHgRmiukU",
    "aws_user_pools_web_client_id": "75jlh67esgfc27m7ln8fujlcdi",
    "aws_mobile_analytics_app_id": "49061f65a4264a74b63c098597c8ac2a",
    "aws_mobile_analytics_app_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://yqdgqwtqc5bdnef75l36fufcim.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};
const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://w6j24h7sf5dhtjan4n32tvc2aq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-dsv4xiwebjfx5ifssdxoxrnwqm",
    "aws_cognito_identity_pool_id": "us-east-1:923ba9a1-9987-47b1-ac28-c6dbcf1673c3",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_IRSGmPayI",
    "aws_user_pools_web_client_id": "4avtl6g9tg72aqmacdkdd0q1uc",
    // "federationTarget": "COGNITO_IDENTITY_POOLS",
    // "aws_content_delivery_bucket": "vezivo-hosting-dev",
    // "aws_content_delivery_bucket_region": "us-east-1",
    // "aws_content_delivery_url": "http://vezivo-hosting-dev.s3-website-us-east-1.amazonaws.com"
};




export default awsmobile;
