import React from 'react';
import  moment from  'moment'
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CardActions from '@material-ui/core/CardActions';
import ShareIcon from '@material-ui/icons/Share';
import EditIcon from '@material-ui/icons/Edit';
import Dialog  from "@material-ui/core/Dialog";
import CopyIcon from '@material-ui/icons/Assignment';
import DialogActions  from "@material-ui/core/DialogActions";
import _ from 'lodash';
import { Formik } from "formik";
import {UpdateEventForm, updateEventSchema} from "../dash/updateEventForm.js";
import withStyles from "@material-ui/core/styles/withStyles"
import withMobileDialog from '@material-ui/core/withMobileDialog'
import DialogTitle  from "@material-ui/core/DialogTitle"
import DialogContent  from "@material-ui/core/DialogContent"
const {CAFE} = window;

const styles = theme => ({
    actions: {
      display: 'flex',
      padding: 0
    },
    expand: {
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
      marginLeft: 'auto',
      [theme.breakpoints.up('sm')]: {
        marginRight: 0,
      },
    },
    expandOpen: {
      transform: 'rotate(180deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
      marginLeft: 'auto',
      [theme.breakpoints.up('sm')]: {
        marginRight: 0,
      },
    },
    
  })
  



  class PopulateData extends React.Component
  {
      constructor(props) {
          super(props)
          this.state = {
            updateEventOpen: false,
            showCalShare: false,
            expanded: false
          }
        }
      
      handleExpandClick = () => {
      
        this.setState({expanded: !this.state.expanded})
        
        }
        
      handleShowCalShare = (event) => {
          const {showCalShare} = this.state
          this.setState({showCalShare: !showCalShare, showCalShareTarget: event.currentTarget})
          console.log('check-this', this.state.showCalShare)
      }
      handleCopyToClipboard = (text) => {
        var dummy = document.createElement("input");
        document.body.appendChild(dummy);
        dummy.setAttribute('value', text);
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        alert(`Copied ${text} to clipboard!`)
      }
  
      handleShowUpdateEvent = (event) => {
        const {auth} = this.props;
        const accountId = auth && auth.account ? auth.account.id : false;
        if(!accountId){
          this.props.handleShowSignInDialog(this.props);
          return;
        }
        this.setState({updateEventOpen: true});
      }  

      handleHideUpdateEvent = () => {
        this.setState({updateEventOpen: false});
      } 
     
  
  
      render(){
        console.log('halma',this.props.events)
        const {startAt,endAt,title,isAllDay,location} = this.props.createdEvent;
        const {startStamp,classes,currentCal,fullScreen,createdEvent, eventStartAt, eventEndAt} = this.props;
  
        const event = createdEvent;
        const actualEvent = this.props.actualEvent;

        const today = moment().format('ddd MMM D');
        const eveDate = moment(startStamp).format('ddd MMM D');
        let displayHeader = this.props.displayHeader;
        console.log('function',currentCal.accessKeys)

        const updateEventValues = !this.state.updateEventOpen ? false :CAFE.setEmptyStringProps({ title: event.title, content: event.content, tags:event.tags || [], location: event.location, url: event.url, isAllDay: event.isAllDay, startAt: eventStartAt, endAt: eventEndAt })
          
      const siteUrl = process.env.SITE_URL || "https://mycafe.mobi";
      // const adminKeys = _.filter(currentCal.accessKeys, key => key.accessType === "ADMIN");
      // const ownerKeys = _.filter(currentCal.accessKeys, key => key.accessType === "OWNER")
      var updateDialog;

      const userAccessLevelKey = "5wfahwFcwm";
        //Update Event
         updateDialog = !this.state.updateEventOpen ? false :  ( 
          <Dialog
            fullScreen={fullScreen}
            open={this.state.updateEventOpen}
            onClose={this.handleHideUpdateEvent}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">{`${currentCal.title} - Modify Event `}</DialogTitle>
            <DialogContent>
              <Formik
                  render={props => <UpdateEventForm {...props} currentCal={currentCal} />}
                  initialValues={updateEventValues}
                  validationSchema={updateEventSchema}
                  onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                          CAFE.trigger("UPDATE_EVENT", {
                              ...values,
                              event: actualEvent,
                              calId: currentCal.id,
                              userAccessLevelKey: userAccessLevelKey,
                              onError: (result) => { console.log("UPDATE EVENT ERROR:", result); alert("ERROR"); setSubmitting(false) },
                              onSuccess: (result) => { 
                                  console.log("UPDATE EVENT SUCCESS:", result)
                                  CAFE.trigger("LOAD_CAL", {calId:currentCal.id})
                                  setSubmitting(false)
                                  this.handleHideUpdateEvent()
                              }
                          })
                      }, 400);
                  }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleHideUpdateEvent} color="primary" autoFocus>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        )
        
    return(
        <div  onDoubleClick={this.handleExpandClick.bind(this, event)}>
        {updateDialog}           
       
    <div>
        <div className="col-12 agenda-date " onDoubleClick={this.handleExpandClick.bind(this, event)}>

        <span className= {`eveDate=== today ? 'my-curDay':'' ${displayHeader ? '' : 'conditional'}`}>{eveDate}
        
        </span>
                      <IconButton  color="secondary"
                        className={this.state.expanded ? classes.expandOpen : classes.expand}
                        onClick={this.handleExpandClick.bind(this, event)}
                        aria-expanded={this.state.expanded}
                        aria-label="Show more"
                        style={{position:'absolute', right:'0', top:'0'}}>
                        <ExpandMoreIcon />
                      </IconButton>



                      </div>
        
        <div className="col-12">
        <div className="row agenda">
            {!isAllDay && (
                <div className="col-4 agenda-timings">

                <p className="agenda-time">{startAt}</p>
                <p className="agenda-time">{endAt}</p>
                </div>

            )}
        {!isAllDay || (
        <div className="col-4 agenda-timings">

        <p className="agenda-time">all-day</p>
        </div>

        )  
        }
               
            <div className="col-8 title-location">
                <p className="title">{title}</p>
                <p className="location">{location}</p>
            </div>
            <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                    <div className={classes.eventContent}>
                    <CardActions className={classes.actions} disableActionSpacing>
                      <IconButton color="secondary" aria-label="Add to favorites" title="Add to favorites">
                        <FavoriteIcon />
                      </IconButton>
                      <IconButton color="secondary" aria-label="Share Calendar" title="Share Calendar"
                      style={{position:'relative'}}>
                        <ShareIcon />
                      </IconButton>
                      <IconButton color="secondary" aria-label="Edit" title="Edit" 
                       style={{position:'absolute', right:'0'}}
                      onClick={this.handleShowUpdateEvent.bind(this, event)}>
                        <EditIcon/>
                     </IconButton>
                    </CardActions>
                    </div> 

            </Collapse>
            <Dialog
          fullScreen={fullScreen}
          open={this.state.showCalShare}
          onClose={this.handleShowCalShare}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{`${currentCal.title} - Share Calendar `}</DialogTitle>
          <DialogContent>
            <div style={{padding: "10px 0", maxWidth: "400px", display: this.state.showCalShare ? "block" : "none"}}>
                <p className={classes.infoBox}>You can share this calendar with anyone... simply send them one of the following links!</p>
               <div>
                 <div style={{fontSize: "14px"}}>View only access:</div>
                 <div style={{display: "flex", flexDirection: "row"}}>
                   <div style={{flex: 1, fontSize: "12px", padding: "4px", margin: "10px 0", border:"1px solid #ddd", overflowX: "auto"}}>{`${siteUrl}/${currentCal.id}`}</div>
                   <IconButton color="secondary" aria-label="Copy to clipboard" title="Copy to clipboard" onClick={this.handleCopyToClipboard.bind(this, `${siteUrl}/${currentCal.id}`)}>
                      <CopyIcon />
                    </IconButton>
                  </div>
                 {/* {!adminKeys.length ||  (
                 <div>
                   <div style={{fontSize: "14px"}}>Admin access (create, update, delete events):</div>
                   {adminKeys.map( accessKey => (
                     <div key={accessKey.key} style={{display: "flex", flexDirection: "row"}}>
                       <div style={{flex: 1, fontSize: "12px", padding: "4px", margin: "10px 0", border:"1px solid #ddd", overflowX: "auto"}}>{`${siteUrl}/${currentCal.id}?key=${accessKey.key}`}</div>
                       <IconButton color="secondary" aria-label="Copy to clipboard" title="Copy to clipboard" onClick={this.handleCopyToClipboard.bind(this, `${siteUrl}/${currentCal.id}?key=${accessKey.key}`)}>
                          <CopyIcon />
                        </IconButton>
                    </div>))}
                  </div>
                  )}
                 {!ownerKeys.length ||  (
                 <div>
                    <div style={{fontSize: "14px"}}>Owner access (only share this if you are sure!):</div>
                   {ownerKeys.map( accessKey => (
                    <div key={accessKey.key} style={{display: "flex", flexDirection: "row"}}>
                     <div style={{flex: 1, fontSize: "12px", padding: "4px", margin: "10px 0", border:"1px solid #ddd", overflowX: "auto"}}>{`${siteUrl}/${currentCal.id}?key=${accessKey.key}`}</div>
                     <IconButton color="secondary" aria-label="Copy to clipboard" title="Copy to clipboard" onClick={this.handleCopyToClipboard.bind(this, `${siteUrl}/${currentCal.id}?key=${accessKey.key}`)}>
                        <CopyIcon />
                      </IconButton>
                    </div>))}
                  </div>
                  )} */}
               </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleShowCalShare} color="primary" autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog> 
        </div>
    </div>
     </div>
</div>
    )

}
}
export default withMobileDialog()(withStyles(styles)(PopulateData));