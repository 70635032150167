import React, { Component } from 'react';
import '../AppCalendar.css';

import { Link } from 'react-router-dom'
// import { Link } from 'react-router-hash-link';
// import { Link } from 'react-router5';
import  moment from  'moment/moment';
const {CAFE} = window;

class Month extends Component {

    weekdayshort = moment.weekdaysShort();
    constructor(props) {
        super(props)
        var month = this.props.month;
        var year = this.props.year;
        var className = this.props.className;
        var monthName = moment().month(month).format("MMM");
        // var today = month().year();
        this.state = {
            dateObject: moment().year(year).month(month),
            'year' : year,
            'currentMonth'  : moment().month(),
            'currentYear'  : moment().year(),
            'month' : month,
            'today' : moment().date(),
            'monthName' : monthName,
            'className' : className,
            'yearDetail' : false,
            'pathName' : '/YearDetail#' . monthName,
        }

        this.yearDetail = this.yearDetail.bind(this);

    }
    daysInMonth = () => {
        return this.state.dateObject.daysInMonth();
    };
    currentDay = () => {
        return this.state.dateObject.format("D");
    };

    firstDayOfMonth = () => {
        let dateObject = this.state.dateObject;
        let firstDay = moment(dateObject)
            .startOf("month")
            .format("d");
        return firstDay;
    };
    animate (){
        console.log('animate');
      // const element = document.getElementsByClassName('col-sm-6 col-md-4 col-lg-3 flex-month');
        //console.log(element);
      // console.log(this.state.yearDetail);
        if(this.state.yearDetail == true) {
            this.setState({yearDetail: false});
        } else {
            this.setState({yearDetail: true})
        }

    }
    componentWillReceiveProps(nextProps){
        if(nextProps.className!==this.props.className){
            //Perform some operation
            this.setState({className: nextProps.className});
          //  this.classMethod();
        }
    }
    yearDetail = () => {
        console.log('year detail');
        console.log(this.state.yearDetail);
        //this.setState({yearDetail: true});
        this.state.yearDetail = true;
        console.log(this.state.yearDetail);
        const store = CAFE.getStore()
        console.log("** STORE cd **", store.toJS())


    }

    render() {
        let weekdayshortname = this.weekdayshort.map(day => {
            return <th key={day}>{day}</th>;
        });
        const checkDev = this.props.mblDevice;
        let blanks = [];
        for (let i = 0; i < this.firstDayOfMonth(); i++) {
            blanks.push(<td className={`empty ${checkDev ? 'mblCalendar-day' : 'calendar-day'} `}>{""}</td>);
        }
        var today = new Date();
        var year = moment.currentYear;
        var lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
        let daysInMonth = [];

        for (let d = 1; d <= this.daysInMonth(); d++) {
            let calDate =  moment(new Date(this.state.year,this.state.month,d)).format('YYYY-MM-DD');

            let showBlueBg = false;
            const firstEventStartDate =  this.props.firstEventStartDate;
            const lastEventEndDate =  this.props.lastEventEndDate;

            if(calDate >= firstEventStartDate && calDate <= lastEventEndDate){
                showBlueBg = true;
            }
                    
            if (this.state.month === this.state.currentMonth && this.state.year === this.state.currentYear && this.state.today === d) {
                daysInMonth.push(
                    <td key={d} className={`red-list
                    ${ showBlueBg ? 'tdBckgrnd' : ''}
                    ${checkDev ? 'mblCalendar-day' : 'calendar-day'}`} >
                            {d}
                              </td>
                );
            }else{
                daysInMonth.push(
                    <td key={d} className={`${ showBlueBg
                        ? 'tdBckgrnd' : ''}
                        ${checkDev ? 'mblCalendar-day' : 'calendar-day'}`} >
                        {d}
                    </td>
                );
            }

        }
        var totalSlots = [...blanks, ...daysInMonth];
        let rows = [];
        let cells = [];

        totalSlots.forEach((row, i) => {
            if (i % 7 !== 0) {
                cells.push(row);
            } else {
                rows.push(cells);
                cells = [];
                cells.push(row);
            }
            if (i === totalSlots.length - 1) {
                // let insertRow = cells.slice();
                rows.push(cells);
            }
        });

        let daysinmonth = rows.map((d, i) => {
                //console.log(d);
                return <tr>{d}</tr>;

        });

        return (
            // className={this.state.className}
            <div   className={ this.state.className} id={this.state.monthName} >

                {/*<Link style={{ textDecoration: 'none' , color: 'black' , width : '85%'}} routeName="yearDetail"   >*/}
                       {/*to={{pathname: "/YearDetail", state: { monthName : this.state.monthName , year : this.state.year }}}>*/}
                  <table className={`full-width ${checkDev ? 'mblCalendar-day' : 'calendar-day'}`} 
                  onClick={() => this.props.yearDetail( this.props)} >
                        {/*onClick={this.animate}*/}
                    <thead>
                    {/*<tr>{weekdayshortname}</tr>*/}
                    {/*className="month"*/}
                    <tr className={(this.state.month === this.state.currentMonth && this.state.year === this.state.currentYear) ? 'month-current' : 'month'}>{this.state.monthName}</tr>
                    </thead>
                    {/*<Link  style={{ textDecoration: 'none' , color: 'black' }} to={{pathname: "/Day", state: { date : '2019-01-11' }}}>*/}
                        <tbody >{daysinmonth}</tbody>
                    {/*</Link>*/}

                </table>

                {/*</Link>*/}

            </div>

        );
    }

}


export default Month;