import gql from 'graphql-tag'
import generate_nanoid from 'nanoid/generate'
import {toDate} from 'date-fns'
let {CAFE} = window


CAFE.onTrigger("CREATE_FOLDER", (params) => {

    if(!params.accountId || !params.title) return
    //const store = CAFE.getStore()
    CAFE.setBusy("CREATE_FOLDER", true)
    
    ;(async () => { 
        let folder = false;
        const accountId = params.accountId;
        const client = CAFE.getClient();
        await client.hydrated();
        
        if(!params.title) return;
        
        // Cal
        const saveAt = (new Date()).toISOString()
        const newFolderId = `+${generate_nanoid(CAFE.nanoIdAlphabet, 12)}`

        const newFolder = CAFE.removeEmptyProps( {
            id: newFolderId,
            title: params.title,
            folderId: newFolderId,
        	accountId: accountId,
        	modifiedAt: saveAt,
        	createdAt: saveAt,
        	createdBy: accountId
          })
        console.log("CREATING FOLDER...:", newFolder)
        // create user if not found
        const createResult = await client.mutate({
            mutation: gql(`mutation CreateFolder($input: CreateFolderInput!) {
              createFolder(input: $input) {
                id
                title
                accountId
                folderId
              }
            }
            `),
            variables: {
              input: newFolder
            }
        })
        console.log("CREATED FOLDER, FOUND:", createResult)
        folder = createResult.data ? createResult.data.createFolder : false
        
        if(!folder || !folder.id) if(params.onError) params.onError([{message: "Unable to create new folder"}])
        
        if(params.onSuccess) params.onSuccess(folder)

        
        CAFE.setBusy("CREATE_FOLDER", false)
    })()
    
})

CAFE.onTrigger("CREATE_CAL", (params) => {
  debugger;
  if(!params.accountId || !params.title) return
    //const store = CAFE.getStore()
    CAFE.setBusy("CREATE_CAL", true)
    
    ;(async () => { 
        let cal = false
        const accountId = params.accountId
        const folderId = params.folderId
        const client = CAFE.getClient()
        await client.hydrated()
        
        if(!params.title) return
        
        // Cal
        const saveAt = (new Date()).toISOString()
        const newCalId = `+${generate_nanoid(CAFE.nanoIdAlphabet, 12)}`
        const ownerKey = `${generate_nanoid(CAFE.nanoIdAlphabet, 10)}`
        const adminKey = `${generate_nanoid(CAFE.nanoIdAlphabet, 10)}`
        const newCal = CAFE.removeEmptyProps( {
            id: newCalId,
            title: params.title,
            description: params.description,
            requireApprovalForView: params.requireApprovalForView,
            calId: newCalId,
        	accountId: accountId,
        	folderId: folderId,
        	modifiedAt: saveAt,
        	createdAt: saveAt,
        	createdBy: accountId,
        	accessKeys: [
        	    { key: ownerKey, accessType: "OWNER", requireApproval: params.requireApprovalForOwner },
        	    { key: adminKey, accessType: "ADMIN", requireApproval: params.requireApprovalForAdmin }
        	]
          })
        console.log("CREATING CAL...:", newCal)
        // create user if not found
        const createResult = await client.mutate({
            mutation: gql(`mutation CreateCal($input: CreateCalInput!) {
              createCal(input: $input) {
                id
                title
                accountId
                calId
                description
                accessKeys {
                  key
                  accessType
                  requireApproval
                }
              }
            }
            `),
            variables: {
              input: newCal
            }
        })
        console.log("CREATED CAL, FOUND:", createResult)
        cal = createResult.data ? createResult.data.createCal : false
        
        if(!cal || !cal.id) if(params.onError) params.onError([{message: "Unable to create new calendar"}])
        
        // Save Cal
        let savedCal = false
        const newSavedCal = CAFE.removeEmptyProps( {
            id: `${accountId}|${cal.id}`,
            folderId: folderId,
            savedCalCalId: cal.id,
            friendlyTitle: cal.title,
            isApprovedForView: true,
            isApprovedForAdmin: true,
            isApprovedForOwner: true,
            accountId: accountId,
            savedCalAccountId: accountId,
            modifiedAt: saveAt,
            createdAt: saveAt,
            createdBy: accountId,
            accessKeys: [ownerKey, adminKey]
        })
        console.log("CREATING SAVEDCAL...:", newSavedCal)
        // create user if not found
        const createSCResult = await client.mutate({
            mutation: gql(`mutation CreateSavedCal($input: CreateSavedCalInput!) {
                              createSavedCal(input: $input) {
                                id
                                accountId
                                friendlyTitle
                                accessKeys
                                cal {
                                  id
                                  title
                                }
                              }
                            }
                            `),
            variables: {
              input: newSavedCal
            }
        })
        console.log("CREATED SAVEDCAL, FOUND:", createSCResult)
        savedCal = createSCResult.data ? createSCResult.data.createSavedCal : false
        
        if(!savedCal || !savedCal.id) if(params.onError) params.onError([{message: "Unable to create new saved calendar"}])
        
        if(params.onSuccess) params.onSuccess(savedCal)

        
        CAFE.setBusy("CREATE_CAL", false)
    })()
    
})

CAFE.onTrigger("UPDATE_CAL", (params) => {
    if(!params.calId || !params.accountId ) return
    CAFE.setBusy("UPDATE_CAL", true)
    
    ;(async () => { 
        const client = CAFE.getClient()
        await client.hydrated()
        
        // Cal
        //const saveAt = (new Date()).toISOString()
        const saveCal =  CAFE.setEmptyPropsNull({
            id: params.calId,
            title: params.title,
            description: params.description,
            tags: params.tags,
            requireApprovalForView: params.requireApprovalForView
          })
        console.log("UPDATING CAL...:", saveCal)
        // create user if not found
        const updateResult = await client.mutate({
            mutation: gql( `mutation UpdateCal($input: UpdateCalInput!) {
                            updateCal(input: $input) {
                                id
                                calId
                                title
                              }
                            }
                            `),
            variables: {
              input: saveCal
            }
        })
        console.log("UPDATED CAL, FOUND:", updateResult)
        let cal = updateResult.data ? updateResult.data.updateCal : false
        
        if(!cal || !cal.id) if(params.onError) params.onError([{message: "Unable to update cal"}])
        
      
        
        if(params.onSuccess) params.onSuccess(cal)

        
        CAFE.setBusy("UPDATE_CAL", false)
    })()
})

CAFE.onTrigger("FETCH_SAVEDCALS", (params) => {
    if(!params.accountId) return
    CAFE.setBusy("FETCH_SAVEDCALS", true)
     
    const accountId = params.accountId
    // let startAt = params.startAt ? toDate(params.startAt) : new Date()
    // startAt = new Date(startAt.getFullYear(), startAt.getMonth(), startAt.getDate(), 0, 0, 0, 0)
    const client = CAFE.getClient()
    client.hydrated().then(() => {
        client.query({
            query: gql(`query ListSavedCals(
                $filter: ModelSavedCalFilterInput
                $limit: Int
                $nextToken: String
            ) {
                listSavedCals(filter: $filter, limit: $limit, nextToken: $nextToken) {
                    items {
                          id
                          accountId
                          folderId
                          cal {
                            id
                            title
                            description
                          }
                          folder {
                            id
                            title
                            accountId
                            folderId
                            modifiedAt
                            createdAt
                            createdBy
                          }
                          friendlyTitle
                          accessKeys
                          isApprovedForOwner
                          isApprovedForAdmin
                          isApprovedForView
                          color
                    }
                    nextToken
                }
            }
            `),
            variables: CAFE.removeEmptyProps({filter: {accountId: {eq: accountId}}, limit: params.limit || 200, nextToken: params.nextToken || ""}),
            fetchPolicy: 'network-only',
        }).then( (fetchResult) => {
            console.log("FETCHED SAVEDCALS, FOUND:", fetchResult)
            
            // save cals
            let savedCals = fetchResult.data && fetchResult.data.listSavedCals ? fetchResult.data.listSavedCals.items : false
            CAFE.getStore().cal.set({savedCals: savedCals})
            
            // remove any recent cals that have been saved
            let recentCals = JSON.parse(window.sessionStorage.getItem('recentCals') || '[]') || []
            recentCals = recentCals.filter((recentCal) => {return !savedCals.some((savedCal) => savedCal.cal.id === recentCal.id)})
            window.sessionStorage.setItem('recentCals', JSON.stringify(recentCals))
            CAFE.getStore().cal.set({recentCals: recentCals})
            
            CAFE.setBusy("FETCH_SAVEDCALS", false)
        })
    })

})


CAFE.onTrigger("LOAD_CAL", (params) => {
    if(!params.calId) return
    CAFE.setBusy("LOAD_CAL", true)
     
    const calId = params.calId
    const client = CAFE.getClient()
    client.hydrated().then(() => {
        client.query({
            query: gql(`query GetCal($id: ID!) {
              getCal(id: $id) {
                id
                title
                accountId
                calId
                description
                tags
                requireApprovalForView
                accessKeys {
                  key
                  accessType
                  requireApproval
                }
                eventLinks {
                  items {
                    id
                    tags
                    event {
                        id
                        title
                        location
                        tags
                        url
                        content
                        startAt
                        endAt
                        isAllDay
                    }
                  }
                  nextToken
                }
                schedules {
                  id
                  summary
                  recurs {
                    id
                    startAt
                    endAt
                    every
                    interval
                    dayOfMonth
                    dayOfYear
                    maxCount
                    createdAt
                    createdBy
                  }
                  eventIds
                  createdAt
                  createdBy
                }
                modifiedAt
                createdAt
                createdBy
              }
            }
            `),
            variables: CAFE.removeEmptyProps({id: calId}),
            fetchPolicy: 'network-only',
        }).then( (fetchResult) => {
            console.log("LOAD CAL, FOUND:", fetchResult)
            
            // Set current cal
            let currentCal = fetchResult.data && fetchResult.data.getCal ? fetchResult.data.getCal : false
            CAFE.getStore().cal.set({currentCal: currentCal}).now()
            
            
            // Add to recent if not already saved
            const {app, cal} = CAFE.getStore()
            if(!app.auth || !cal.savedCals || !cal.savedCals.some((savedCal) => savedCal.cal.id === currentCal.id ))
            {
                let recentCals = JSON.parse(window.sessionStorage.getItem('recentCals') || '[]') || []
                if(!recentCals.some((recentCal) => recentCal.id === currentCal.id)) {
                    recentCals.push({id: currentCal.id, title: currentCal.title, accessKeys: params.accessKeys || []})
                    window.sessionStorage.setItem('recentCals', JSON.stringify(recentCals))
                }
                CAFE.getStore().cal.set({recentCals: recentCals})
            }
            
            CAFE.setBusy("LOAD_CAL", false)
        })
    })

})


CAFE.onTrigger("CREATE_EVENT", (params) => {
    if(!params.accountId || !params.title || !params.calId) return
    CAFE.setBusy("CREATE_EVENT", true)
    
    console.log("eventDate params1:", params.startAt);
    console.log("eventDate params2:", Date.parse(params.startAt));

    ;(async () => { 
        let event = false
        const accountId = params.accountId
        const client = CAFE.getClient()
        await client.hydrated()
        
        
        // Event
        const saveAt = (new Date()).toISOString()
        const saveEventId = `${generate_nanoid(CAFE.nanoIdAlphabet, 20)}`
        const saveEvent = CAFE.removeEmptyProps( {
            id: saveEventId,
            title: params.title,
            location: params.location,
            tags: params.tags,
            url: params.url,
            content: params.content,
            isAllDay: params.isAllDay,
            isPrivate: false,
            startAt: toDate(Date.parse(params.startAt)).toISOString(),
            endAt: params.endAt ? toDate(Date.parse(params.endAt)).toISOString() : "",
            calId: params.calId,
            ownerKey: params.userAccessLevelKey,
        	organizer: {accountId: accountId},
        	//modifiedAt: saveAt,
        	createdAt: saveAt,
        	createdBy: accountId
          })
        console.log("CREATING EVENT...:", saveEvent)
        // create user if not found
        const createResult = await client.mutate({
            mutation: gql( `mutation CreateEvent($input: CreateEventInput!) {
                              createEvent(input: $input) {
                                id
                                calId
                                title
                              }
                            }
                            `),
            variables: {
              input: saveEvent
            }
        })
        console.log("CREATED EVENT, FOUND:", createResult)
        event = createResult.data ? createResult.data.createEvent : false
        
        if(!event || !event.id) if(params.onError) params.onError([{message: "Unable to create new event"}])
        
        // Link
        let link = false
        const newLink = CAFE.removeEmptyProps( {
            id: `${accountId}|${event.id}`,
            accountId: accountId,
            linkCalId: params.calId,
            linkEventId: event.id,
            //tags: params.tags,
            timezone: "default",
            createdAt: saveAt,
            createdBy: accountId
        })
        console.log("CREATING LINK...:", newLink)
        // create user if not found
        const createLinkResult = await client.mutate({
            mutation: gql(`mutation CreateLink($input: CreateLinkInput!) {
                              createLink(input: $input) {
                                id
                                accountId
                                tags
                                timezone
                                cal {
                                  id
                                }
                                event {
                                  id
                                  calId
                                  scheduleId
                                  organizer {
                                    accountId
                                    name
                                    email
                                  }
                                  title
                                  content
                                  url
                                  location
                                  startAt
                                  endAt
                                  isAllDay
                                }
                              }
                            }
                            `),
            variables: {
              input: newLink
            }
        })
        console.log("CREATED LINK, FOUND:", createLinkResult)
        link = createLinkResult.data ? createLinkResult.data.createLink : false
        
        if(!link || !link.id) if(params.onError) params.onError([{message: "Unable to create new link"}])
        
        if(params.onSuccess) params.onSuccess(link)

        
        CAFE.setBusy("CREATE_EVENT", false)
    })()
    
})
CAFE.onTrigger("UPDATE_EVENT", (params) => {

    if(!params.event || !params.event.id ) return
    CAFE.setBusy("UPDATE_EVENT", true)
    
    ;(async () => { 
        let event = params.event
        const client = CAFE.getClient()
        await client.hydrated()
        
        // Event
        //const saveAt = (new Date()).toISOString()
        const saveEvent =  CAFE.setEmptyPropsNull({
            id: event.id,
            title: params.title,
            location: params.location,
            tags: params.tags,
            url: params.url,
            content: params.content,
            isAllDay: params.isAllDay,
            isPrivate: false,
            ownerKey: params.userAccessLevelKey,
            startAt: toDate(Date.parse(params.startAt)).toISOString(),
            calId: params.calId,
            endAt: params.endAt ? toDate(Date.parse(params.endAt)).toISOString() : ""
          })
        console.log("UPDATING EVENT...:", saveEvent)
        // create user if not found
        const updateResult = await client.mutate({
            mutation: gql( `mutation UpdateEvent($input: UpdateEventInput!) {
                            updateEvent(input: $input) {
                                id
                                calId
                                title
                              }
                            }
                            `),
            variables: {
              input: saveEvent
            }
        })
        console.log("UPDATED EVENT, FOUND:", updateResult)
        event = updateResult.data ? updateResult.data.updateEvent : false
        
        if(!event || !event.id) if(params.onError) params.onError([{message: "Unable to update event"}])
        
      
        
        if(params.onSuccess) params.onSuccess(event)

        
        CAFE.setBusy("UPDATE_EVENT", false)
    })()
    
})
