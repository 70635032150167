import React, { Component } from 'react'
// import _ from 'lodash'
import TagChip from './tagChip.js'
import { Formik } from "formik"
import {UpdateEventForm, updateEventSchema} from "./updateEventForm.js"
import {CreateEventForm, createEventSchema} from "./createEventForm.js"
import {addHours, format, toDate} from 'date-fns'
import Button  from "@material-ui/core/Button"
import Dialog  from "@material-ui/core/Dialog"
import DialogTitle  from "@material-ui/core/DialogTitle"
import DialogContent  from "@material-ui/core/DialogContent"
//import DialogContentText  from "@material-ui/core/DialogContentText"
import DialogActions  from "@material-ui/core/DialogActions"
import SnackbarContent  from "@material-ui/core/SnackbarContent";
import withMobileDialog from '@material-ui/core/withMobileDialog'
// import Card from '@material-ui/core/Card'
// import CardHeader from '@material-ui/core/CardHeader'
//import CardMedia from '@material-ui/core/CardMedia'
//import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import Paper  from "@material-ui/core/Paper"
//import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
//import red from '@material-ui/core/colors/red'
import EditIcon from '@material-ui/icons/Edit'
import FavoriteIcon from '@material-ui/icons/Favorite'
import ShareIcon from '@material-ui/icons/Share'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
//import MoreVertIcon from '@material-ui/icons/MoreVert'
import { Markdown } from 'react-showdown'
import withStyles from "@material-ui/core/styles/withStyles"
const {CAFE} = window

const styles = theme => ({
    actions: {
      display: 'flex',
      padding: 0
    },
    expand: {
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
      marginLeft: 'auto',
      [theme.breakpoints.up('sm')]: {
        marginRight: 0,
      },
    },
    expandOpen: {
      transform: 'rotate(180deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
      marginLeft: 'auto',
      [theme.breakpoints.up('sm')]: {
        marginRight: 0,
      },
    },
    
    eventContainer: {
      touchAction: "manipulation",
      maxWidth: 400,
      margin: theme.spacing.unit,
      display: "flex",
      flexDirection: "row",
      justifyContent: "spacing",
      cursor: "pointer",
    },
    eventContentContainer: {
      flex: 1
    },
    eventHeader: {
      padding: "4px 8px",
      height: "90px",
      overflow: "hidden",
    },
    eventTime: {
      color: theme.palette.secondary.light,
      fontSize: "12px",
    },
    eventTitle: { 
      color: "#000",
      fontSize: "16px",
    },
    eventTags: {
      textAlign: "right",
    },
    eventLocation: { 
      fontSize: "12px",
    },
    eventColorBar: {
      width: "5px",
      backgroundColor: theme.palette.secondary.light,
      flex: 1,
    },
    eventContent: {
      padding: "0 8px",
    },
    eventLink: {
      fontSize: "14px",
      paddingTop: "8px",
    },
    dateAvatar: {
      float: "left", 
      position: "relative", 
      margin: "0px", 
      backgroundColor: theme.palette.secondary.main,
      color:"#fff", 
      padding: "5px", 
      //borderBottomRightRadius:"5px"
    },
    dateAvatarMonth: {
      display:"block",
      textAlign:"center",
      fontSize:"12px",
      lineHeight:"12px",
      padding:"2px",
      color:"#fff",
      textTransform:"uppercase"
    },
    dateAvatarDay: {
      display:"block",
      textAlign:"center",
      fontSize:"24px",
      lineHeight:"22px",
      fontWeight:"bold",
      paddingBottom:"2px"
    },
    dateAvatarDayOfWeek: {
      display:"block",
      textAlign:"center",
      fontSize:"10px",
      lineHeight:"10px",
      textTransform:"uppercase",
      padding:"2px"
    },
  
  
})

class Event extends Component {

  handleSignInButton = () => {
      if(this.props.OAuthSignIn) this.props.OAuthSignIn()
  }

  constructor(props) {
    super(props)
    this.state = {
      updateEventOpen: false,
      expanded: false
    }
  }

  componentDidMount = () => {
  }
  
  handleShowUpdateEvent = (event) => {
    this.setState({updateEventOpen: true})
  }  
  handleHideUpdateEvent = () => {
    this.setState({updateEventOpen: false})
  }  
  handleExpandClick = (linkId) => {
    // const {expanded} = this.state
    // if(expanded.indexOf(linkId) > -1) expanded.splice(expanded.indexOf(linkId), 1)
    // else expanded.push(linkId)
    this.setState({expanded: !this.state.expanded})
  }  

  render = () => {
    const me = this
    const {auth, event, classes, fullScreen, currentCal, userAccessLevelKey} = me.props
    const accountId = auth && auth.account ? auth.account.id : false

    if(!accountId) return ( <SnackbarContent className={classes.snackbarContent}
      message={<span id="message-id">You must be signed in to create an event!</span>}
      action={[
        <Button key="signin" color="secondary" size="large" onClick={me.handleSignInButton}>
          Sign In or Sign Up
        </Button>
      ]}
    /> )

    const eventDate = new Date();
    eventDate.setMinutes(0);
    eventDate.setSeconds(0);
    const eventDateEnd = new Date();
    eventDateEnd.setMinutes(0);
    eventDateEnd.setSeconds(0);
    eventDateEnd.setHours(eventDateEnd.getHours() + 1);

    console.log("eventDate: ", eventDate);
    console.log("eventDate eventDateEnd: ", eventDateEnd);


    const eventStartAt = eventDate.getTime();
    const eventEndAt = (addHours(eventDate, 1).getTime());
    console.log("event Start At: ", eventStartAt);
    console.log("event End At: ", eventEndAt);

    const createEventValues = !this.props.addEventOpen ? false : { title: "", content: "", location: "", url: "", tags:[], isAllDay: false, startAt: eventDate.toISOString(), endAt: eventDateEnd.toISOString() }
    // const updateEventValues = !this.state.updateEventOpen ? false :CAFE.setEmptyStringProps({ title: event.title, content: event.content, tags:event.tags || [], location: event.location, url: event.url, isAllDay: event.isAllDay, startAt: eventStartAt, endAt: eventEndAt })
    const updateEventValues = !this.props.updateEventOpen ? false :CAFE.setEmptyStringProps({ title: event.title, content: event.content, tags:event.tags || [], location: event.location, url: event.url, isAllDay: event.isAllDay, startAt: eventStartAt, endAt: eventEndAt })


    const dt = toDate(new Date());
    console.log("d t: ", dt);

    const time = event.isAllDay ? "ALL DAY" : `${format(eventStartAt,"h:mma")} - ${format(eventEndAt,"h:mma")}`
    console.log("time: ", time);
    const longStartAt =  format(eventStartAt,"PPPP")
    console.log("longStartAt: ", longStartAt);

    const tags = event.tags || []

    const updateDialog = !this.state.updateEventOpen ? false :  ( 
      <Dialog
        fullScreen={fullScreen}
        open={this.state.updateEventOpen}
        onClose={this.handleHideUpdateEvent}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{`${currentCal.title} - Modify Event `}</DialogTitle>
        <DialogContent>
          <Formik
              render={props => <UpdateEventForm {...props} currentCal={currentCal} />}
              initialValues={updateEventValues}
              validationSchema={updateEventSchema}
              onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                      CAFE.trigger("UPDATE_EVENT", {
                          ...values,
                          event: event,
                          calId: currentCal.id,
                          userAccessLevelKey: userAccessLevelKey,
                          onError: (result) => { console.log("UPDATE EVENT ERROR:", result); alert("ERROR"); setSubmitting(false) },
                          onSuccess: (result) => { 
                              console.log("UPDATE EVENT SUCCESS:", result)
                              CAFE.trigger("LOAD_CAL", {calId:currentCal.id})
                              setSubmitting(false)
                              this.handleHideUpdateEvent()
                          }
                      })
                  }, 400);
              }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleHideUpdateEvent} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
    
    if(this.props.addEventOpen) return (
      <Dialog
          fullScreen={fullScreen}
          open={this.props.addEventOpen}
          onClose={this.props.handleHideAddEvent}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{`${currentCal.title} - Add Event `}</DialogTitle>
          <DialogContent>
            <Formik
                render={props => <CreateEventForm {...props} currentCal={currentCal} />}
                initialValues={createEventValues}
                validationSchema={createEventSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        CAFE.trigger("CREATE_EVENT", {
                            ...values,
                            calId: currentCal.id,
                            accountId: accountId,
                            userAccessLevelKey: userAccessLevelKey,
                            onError: (result) => { console.log("CREATE EVENT ERROR:", result); alert("ERROR"); setSubmitting(false) },
                            onSuccess: (result) => { 
                                console.log("CREATE EVENT SUCCESS:", result)
                                CAFE.trigger("LOAD_CAL", {calId:currentCal.id})
                                setSubmitting(false)
                                this.props.handleHideAddEvent()
                            }
                        })
                    }, 400);
                }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleHideAddEvent} color="primary" autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
    )
          
    return (
            <div>
              {updateDialog}           
              <Paper elevation={1} square className={classes.eventContainer} onDoubleClick={this.handleExpandClick.bind(this, event.link.id)}>
                <div className={classes.dateAvatar} title={longStartAt}>
                  {/*<span style={{"display":"block","position":"absolute","left":"-10px","top":"20px","fontSize":"14px","fontWeight":"bold","lineHeight":"14px","transform":"rotate(270deg)","color":"#aaa"}}>{format(dt,'yyyy')}</span>*/}
                  <span className={classes.dateAvatarMonth}>{format(dt,'MMM')}</span>
                  <span className={classes.dateAvatarDay}>{format(dt,'dd')}</span>
                  <span className={classes.dateAvatarDayOfWeek}>{format(dt,'EEE')}</span>
                </div>
                <div className={classes.eventContentContainer}>
                  <div className={classes.eventHeader}>
                    <Typography className={classes.eventTime}>{time}</Typography>
                    <Typography className={classes.eventTitle}>{event.title}</Typography>
                    <Typography className={classes.eventLocation}>{event.location}</Typography>
                    <div className={classes.eventTags}>
                      {tags.map( tag => <TagChip key={tag} tag={tag} selected={true} /> )}
                    </div>
                  </div>
                  <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                    <div className={classes.eventContent}>
                      <div className="markdown"><Markdown markup={event.content} /></div>
                      {!event.url ? false : <div className={classes.eventLink}><Button href={event.url} className={classes.button} color="secondary" target="_blank" rel="noopener noreferrer" title={event.url}>Launch external link</Button></div>}
                    </div> 
                    <CardActions className={classes.actions} disableActionSpacing>
                      <IconButton color="secondary" aria-label="Add to favorites" title="Add to favorites">
                        <FavoriteIcon />
                      </IconButton>
                      <IconButton color="secondary" aria-label="Share" title="Share">
                        <ShareIcon />
                      </IconButton>
                    </CardActions>
                  </Collapse>
                </div>
                <div style={{display: "flex"}}>
                  <div style={{position: "relative"}}>
                      <IconButton  color="secondary"
                        className={this.state.expanded ? classes.expandOpen : classes.expand}
                        onClick={this.handleExpandClick.bind(this, event.link.id)}
                        aria-expanded={this.state.expanded}
                        aria-label="Show more">
                        <ExpandMoreIcon />
                      </IconButton>
                      <IconButton color="secondary" aria-label="Edit" title="Edit" style={{display: (this.state.expanded ?"block":"none"), position: "absolute", bottom: "0"}} onClick={me.handleShowUpdateEvent.bind(this, event)}>
                        <EditIcon />
                      </IconButton>
                  </div>
                  <div className={classes.eventColorBar} />
                </div>
              </Paper>
            </div>
        )}
              
    
  }



export default withMobileDialog()(withStyles(styles)(Event))

