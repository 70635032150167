import React, { Component } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Amplify, { Auth, Hub, Analytics, Storage } from 'aws-amplify'
import aws_exports from './aws-exports'
import { withOAuth } from 'aws-amplify-react'
import MainNav from './components/mainNav.js'
import TopBar from './components/topBar.js'
import Dash from './dash/dash.js'
import CreateCal from './dash/createCal.js'
import CreateFolder from './dash/createFolder.js'
import Folder from './dash/folder.js'
import Cal from './dash/cal.js'
import About from './about/about.js'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
const {CAFE} = window
const oauth123 = {
    // Domain name
    domain : 'auth.cafe.link', //'cafeauth.auth.us-east-1.amazoncognito.com',  

    // Authorized scopes
    scope : ['phone', 'email', 'profile', 'openid', 'postal_code','aws.cognito.signin.user.admin'], 

    // Callback URL
    redirectSignIn : process.env.REACT_APP_SIGNIN_URL || 'https://06fb7ca4cc0f460c950f4940567966d4.vfs.cloud9.us-east-1.amazonaws.com/', 

    // Sign out URL
    redirectSignOut : process.env.REACT_APP_SIGNOUT_URL || 'https://06fb7ca4cc0f460c950f4940567966d4.vfs.cloud9.us-east-1.amazonaws.com/',

    // 'code' for Authorization code grant, 
    // 'token' for Implicit grant
    responseType: 'code',

    // optional, for Cognito hosted ui specified options
    options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        AdvancedSecurityDataCollectionFlag : true
    }
}
const oauthLocal = {
    domain : 'cafeauth1.auth.us-east-1.amazoncognito.com', //'cafeauth.auth.us-east-1.amazoncognito.com',
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: 'http://localhost:3000/',
    redirectSignOut: 'http://localhost:3000/',
    responseType: 'code' // or token
};
const oauth = {
    domain : 'cafeauth1.auth.us-east-1.amazoncognito.com', //'cafeauth.auth.us-east-1.amazoncognito.com',
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: 'https://dev.mycafe.mobi/',
    redirectSignOut: 'https://dev.mycafe.mobi/',
    responseType: 'code' // or token
};
aws_exports.aws_appsync_authenticationType = 'AWS_IAM'
Amplify.configure(aws_exports)
Auth.configure({ oauth });
Storage.configure({ level: 'private' })
const theme = createMuiTheme({
  palette: {
    primary: {main: '#282c34'},
    secondary: {main: '#659bb6'},
    alt1: {main: '#7365b6'},
    alt2: {main: '#65b680'},
  },
  typography: {
    useNextVariants: true,
  }
})

const appBarHeight = 64;
const styles = theme => ({
  root: {
    display: 'flex',
  },
  h1: {
    color: "pink"
  },
  busyProgress: {
    marginTop: appBarHeight,
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    position: "relative",
    marginTop: appBarHeight,
    paddingLeft: theme.spacing.unit * 1,
    paddingRight: theme.spacing.unit * 1,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing.unit * 3,
      paddingRight: theme.spacing.unit * 3,
      //width: `calc(100% - ${drawerWidth}px)`,
      //marginLeft: drawerWidth,
    }
  }
})


class EmptyComponent extends Component {
    render() { return false }
}

/* ************ APP *************  */
class App extends Component {
  
  constructor(props) {
    super(props);
    this.onHubCapsule = this.onHubCapsule.bind(this);
    this.signOut = this.signOut.bind(this);
    // let the Hub module listen on Auth events
    Hub.listen('auth', this);
    this.state = {
      authState: 'loading',
      mobileOpen: false
    }
  }
  
  componentDidMount() {
    console.log('on component mount')
    Analytics.record('Amplify_CLI')
     // check the current user when the App component is loaded
    Auth.currentAuthenticatedUser().then(user => {
      console.log("MOUNT - found user", user)
      CAFE.trigger("AUTH_CHANGED", user)
      CAFE.setBusy("app", false)
    }).catch(e => {
      console.log("MOUNT - user not found", e)
      CAFE.setBusy("app", false)
    })
  }

  onHubCapsule(capsule) {
    // The Auth module will emit events when user signs in, signs out, etc
    const { channel, payload, /*source*/ } = capsule;
    
    if (channel === 'auth') {
      switch (payload.event) {
        case 'signIn':
         Auth.currentAuthenticatedUser().then(user => {
          console.log("HUB - auth:signIn", user);
          CAFE.trigger("AUTH_CHANGED", user)
        }).catch(e => {
          console.log("HUB - auth:signIn error", e);
        });
          break;
        case 'signIn_failure':
          console.log('not signed in');
          this.setState({authState: 'signIn'});
          CAFE.trigger("AUTH_SIGNED_OUT")
          break;
        default:
          break;
      }
    }
  }

  signOut() {
    Auth.signOut().then(() => {
      CAFE.trigger("AUTH_SIGNED_OUT")
    }).catch(e => {
      console.log("error signing out: ",e);
    });
  }
  handleDrawerToggle = event => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }))
  }
  ensureDrawerClose = event => {
    this.setState(state => ({ mobileOpen: false }))
  }
  
  
  render() {
    const me = this;
    const { classes, store } = me.props
    const { app, cal } = (store || {})
    // if(CAFE.isBusy()) return (

    // )
    
    const route = app.route || {}
    const auth = app.auth || {}
    
    const account = auth && auth.account ? auth.account : false


    const recentCals = cal.recentCals || []
    const savedCals = cal.savedCals || []
    const currentCalId = cal.currentCalId || false
    
    const selectedCals = this.state.selectedCals || []
    const savedFolders = cal.savedFolders || []

    
    let routeProps = {}
    let RouteComponent = EmptyComponent
    switch(route ? route.name : false) {
        case "CAL":
            RouteComponent = Cal
            routeProps.auth = auth
            routeProps.currentCal = cal.currentCal
            routeProps.savedCals = cal.savedCals
            routeProps.savedFolders = cal.savedFolders
            routeProps.selectedCals = cal.selectedCals
            routeProps.OAuthSignIn=this.props.OAuthSignIn 
          break
        case "CREATE_CAL":
            RouteComponent = CreateCal
            routeProps.savedFolders = cal.savedFolders
            routeProps.auth = auth
            routeProps.OAuthSignIn=this.props.OAuthSignIn 
          break
          case "CREATE_FOLDER":
              RouteComponent = CreateFolder
              routeProps.auth = auth
              routeProps.OAuthSignIn=this.props.OAuthSignIn 
            break
        case "CREATE_CAL":
            RouteComponent = CreateCal
            //routeProps.auth = auth
           // routeProps.OAuthSignIn=this.props.OAuthSignIn
            routeProps.year = '2019'
            break
        case "ABOUT":
            RouteComponent = About
          break
          default:
            RouteComponent = Dash
            routeProps.auth = auth
          break
    }
    
    
  
    
    return (
      <div>
      <MuiThemeProvider theme={theme}>
        <React.Fragment>
            <div className={classes.root}>
              <CssBaseline />
              
              <TopBar 
                account={account} 
                OAuthSignIn={this.props.OAuthSignIn} 
                authBusy={CAFE.isBusy(["app", "AUTH_CHANGED"])}
                handleDrawerToggle={this.handleDrawerToggle}   />
                
              <MainNav 
                account={account} 
                savedCals={savedCals} 
                savedFolders={savedFolders} 
                recentCals={recentCals} 
                selectedCals={selectedCals} 
                currentCalId={currentCalId} 
                mobileOpen={this.state.mobileOpen}
                handleDrawerToggle={this.handleDrawerToggle} 
                ensureDrawerClose={this.ensureDrawerClose}  /> 
              
              <div className={classes.content}>
                { CAFE.isBusy() ? <LinearProgress style={{position: "absolute", right:0, width: "100%", height:"10px"}} color="secondary"  /> : false} 
                <RouteComponent {...routeProps} />
              </div>
            </div>
        </React.Fragment>
      </MuiThemeProvider>
      </div>
    );
  }
}
App.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default withOAuth(withStyles(styles)(App));
