import React from 'react'
import * as Yup from "yup"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Switch from "@material-ui/core/Switch"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"
import FormLabel from "@material-ui/core/FormLabel"
import Select from "@material-ui/core/Select"
import TagChip from './tagChip.js'
import OutlinedInput from "@material-ui/core/OutlinedInput"
import MenuItem from "@material-ui/core/MenuItem"
import { DatePicker } from 'material-ui-pickers';
import { DateTimePicker } from 'material-ui-pickers';

export const createEventSchema = Yup.object({
title: Yup.string("Enter a title for your event")
  .required("Title is required")
  .min(3, "Title must contain at least 2 characters")
  .max(80, "Title may contain at most 80 characters"),
content: Yup.string("Enter event content")
  .max(1000, "Description may contain at most 1000 characters"),
url: Yup.string("Enter a url to link your event to an external site")
    .max(250, "Url may contain at most 250 characters")
    .url("Url must be a valid url"),
location: Yup.string("Enter a location for your event")
  .max(250, "Location may contain at most 250 characters"),
startAt: Yup.date("Enter the start date"),
endAt: Yup.date("Enter the end date"),
isAllDay: Yup.boolean(),
tags: Yup.array()
  .max(10, "Each calendar can have at most 10 tags"),
})

export const CreateEventForm = props => {
 const {
   values: { title, content, url, location, isAllDay },
   values,
   errors,
   touched,
   handleChange,
   handleSubmit,
   isSubmitting,
   isValid,
    setFieldValue,
    setFieldTouched,
    currentCal
 } = props
 const change = (name, e) => {
   if(e.persist) {
        e.persist()
        handleChange(e)
   } 
   setFieldTouched(name, true, false)
 }
 
console.log("currentCal", currentCal)   
console.log("wzt Start and end");

   const wztStart = values.startAt;
   const wztend= values.endAt;
   console.log("wzt Start", wztStart);
   console.log("wzt end", wztend);


 return (
    <form onSubmit={handleSubmit}>
        <TextField
            id="title"
            name="title"
            helperText={touched.title ? errors.title : ""}
            error={touched.title && Boolean(errors.title)}
            label="Title"
            value={title}
            onChange={change.bind(null, "title")}
            fullWidth
            margin="normal"
            variant="outlined"
        />
        <TextField
            id="location"
            name="location"
            helperText={touched.location ? errors.location : ""}
            error={touched.location && Boolean(errors.location)}
            label="Location"
            value={location}
            onChange={change.bind(null, "location")}
            fullWidth
            margin="normal"
            variant="outlined"
        />
        <FormControl component="fieldset" style={{display: "block"}} margin="normal">
            <FormLabel component="legend">When</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    id='isAllDay' 
                    checked={isAllDay}
                    onChange={change.bind(null, "isAllDay")}
                    value="isAllDay"
                  />
                }
                label="Is event all day?"
              />
              {isAllDay ? (
              <DatePicker
                id="startAt"
                name="startAt"
                label="Start"
                value={values.startAt}
                onChange={setFieldValue.bind(null, "startAt")}
                onBlur={setFieldTouched.bind(null, "startAt")}
                fullWidth
                margin="normal"
                variant="outlined"
                keyboard
                minDate={new Date('2018-01-01T00:00')}
                format="MM/dd/yyyy"
                disableOpenOnEnter
               // mask={[/\d/,/\d/,/\d/,/\d/,'/',/\d/,/\d/,'/',/\d/,/\d/,' ',/\d/,/\d/,':',/\d/,/\d/,' ',/a|p/i,'M',]}
              /> 
              ) : (
              <DateTimePicker
                id="startAt"
                name="startAt"
                label="Start"
                value={values.startAt}
                onChange={setFieldValue.bind(null, "startAt")}
                onBlur={setFieldTouched.bind(null, "startAt")}
                fullWidth
                margin="normal"
                variant="outlined"
                keyboard
                minDate={new Date('2018-01-01T00:00')}
                format="MM/dd/yyyy hh:mm a"
                disableOpenOnEnter
               // mask={[/\d/,/\d/,/\d/,/\d/,'/',/\d/,/\d/,'/',/\d/,/\d/,' ',/\d/,/\d/,':',/\d/,/\d/,' ',/a|p/i,'M',]}
              />)}
              { isAllDay ? (
              <DatePicker
                id="endAt"
                name="endAt"
                label="End"
                value={values.endAt}
                onChange={setFieldValue.bind(null, "endAt")}
                onBlur={setFieldTouched.bind(null, "endAt")}
                fullWidth
                margin="normal"
                variant="outlined"
                allowKeyboardControl
                keyboard
                animateYearScrolling
                minDate={values.startAt}
                format="MM/dd/yyyy"
                disableOpenOnEnter
              />
              ) : (
              <DateTimePicker
                id="endAt"
                name="endAt"
                label="End"
                value={values.endAt}
                onChange={setFieldValue.bind(null, "endAt")}
                onBlur={setFieldTouched.bind(null, "endAt")}
                fullWidth
                margin="normal"
                variant="outlined"
                allowKeyboardControl
                keyboard
                animateYearScrolling
                minDate={values.startAt}
                format="MM/dd/yyyy hh:mm a"
                disableOpenOnEnter
              />
              )}
            </FormGroup>
        </FormControl>
        <FormControl>
            <FormLabel component="legend">Tags</FormLabel>
            <Select
                id="tags"
                name="tags"
                multiple
                label="Tags"
                fullWidth
                margin="dense"
                value={values.tags}
                onChange={(e) =>  setFieldValue("tags", e.target.value )}
                input={<OutlinedInput fullWidth={true} labelWidth={0} error={touched.tags && Boolean(errors.tags)} />}
                renderValue={selected =>  (
                  <div>
                    {selected.map(tag => (
                      <TagChip key={tag} tag={tag} selected={true} />
                    ))}
                  </div>
                )}
                // MenuProps={MenuProps}
                onBlur={setFieldTouched.bind(null, "tags")}
                error={touched.tags && Boolean(errors.tags)}
                  
              >
                {!currentCal.tags || currentCal.tags.map(tag => (
                  <MenuItem key={tag} value={tag}>
                    {tag}
                  </MenuItem>
                ))}
              </Select>
        </FormControl>
        <TextField
            id="url"
            name="url"
            helperText={touched.url ? errors.url : ""}
            error={touched.url && Boolean(errors.url)}
            label="Url"
            value={url}
            onChange={change.bind(null, "url")}
            fullWidth
            margin="normal"
            variant="outlined"
        />
        <TextField
            id="content"
            name="content"
            multiline
            rows="3"
            rowsMax="10"
            helperText={touched.content ? errors.content : ""}
            error={touched.content && Boolean(errors.content)}
            label="Notes"
            fullWidth
            value={content}
            onChange={change.bind(null, "content")}
            margin="normal"
            variant="outlined"
        />
        <Button
            margin="normal"
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid || isSubmitting}
            style={{minWidth: "100px"}}
        >
            Save
        </Button>
    </form>
 )
}