import Freezer from 'freezer-js'
import createRouter from 'router5'
import browserPlugin from 'router5/plugins/browser'
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync'
import { Auth } from 'aws-amplify'
import aws_config from './aws-exports'




window.CAFE = window.CAFE || (function(){
    //console.log("BUILDING CLIENT", aws_config.aws_appsync_graphqlEndpoint,aws_config.aws_appsync_region,aws_config.aws_appsync_apiKey,AUTH_TYPE.API_KEY)
    const client = new AWSAppSyncClient({
      url: aws_config.aws_appsync_graphqlEndpoint,
      region: aws_config.aws_appsync_region,
      auth: {
        // type: AUTH_TYPE.AWS_IAM,
        credentials: () => Auth.currentCredentials(),
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        jwtToken: async () => (await Auth.currentSession()).getAccessToken().getJwtToken(),
      }
    })
    
    const Store = new Freezer({foo: "bar", busy: ["app"], cal: {}, app: {auth: {}, route: {}} })
    Store.on('beforeAll', function( eventName, params, param2, param3 ){   
        if(eventName === "update") return
        console.log( `REACTION TRIGGERED: ${eventName}`, params)
        return
    })
    Store.on('afterAll', function( eventName, params ){   
        return
    })
    const routes = [
        { name: 'SIGNOUT', path: '/signout' },
        { name: 'HOME', path: '/?:code' },
        { name: 'CREATE_CAL', path: '/create' },
        { name: 'CREATE_FOLDER', path: '/createFolder' },
        { name: 'CAL', path: '/:calId<[^\\?\\+]*\\+[^\\?\\+]*>?:key' },
        { name: 'EVENT', path: '/:calId<[^\\?\\+]*\\+[^\\?\\+]*>/:eventId?:key' },
        { name: 'YEAR', path: '/year' }
    ]
    const routerOptions = {
        allowNotFound: true,
        autoCleanUp: true,
        //defaultRoute: 'home',
        //defaultParams: {},
        queryParams: {
            arrayFormat: 'default',
            nullFormat: 'default',
            booleanFormat: 'default'
        },
        queryParamsMode: 'default',
        trailingSlashMode: 'default',
        strictTrailingSlash: false,
        caseSensitive: false
    }
    const router = createRouter(routes, routerOptions).usePlugin(browserPlugin({ useHash: false }))
    // let authenticatorStateChanger = false

    return {
        
        nanoIdAlphabet: '0123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz',
        cleanNanoId: (id) => { 
            id.replace("i","1").replace("l", "1").replace("O","0") 
            return id
        },
        
        // appsync graphql
        getClient: () => client,
        
        // store and events
        getStore: () => Store.get(),
        trigger: (eventName, params) => Store.emit(eventName, params),
        onTrigger: (eventName, fn) =>  Store.on(eventName, fn),
        
        // auth
        signOut: () => {
            Store.emit("AUTH_SIGNED_OUT")
        },
        // changeAuthState: (state) => {
        //     console.log(`CHANGING AUTH STATE (${state}):`, authenticatorStateChanger)
        //     authenticatorStateChanger && authenticatorStateChanger.changeState(state)
            
        // },
        // setAuthenticatorStateChanger: (obj) => authenticatorStateChanger = obj,
        
        // busy
        setBusy: (key, isSet) => { 
            const store = Store.get()
            const busy = store.busy || []
            const keyPos = busy.indexOf(key)
            if(isSet && keyPos === -1) busy.push(key)
            else if(!isSet && keyPos > -1) busy.splice(keyPos, 1)
            store.set("busy", busy)
        },
        isBusy: (key) => { 
            const store = Store.get()
            let busy = store.busy || ["app"]
            if((!store.app || !store.app.loaded) && busy.indexOf("app") < 0) busy.push("app")
            const result = key ? Array.isArray(key) ? busy.some((el) => key.indexOf(el) > -1) : busy.indexOf(key) > -1 :  busy.length > 0
            //console.log(`BUSY - ${key}:${result}`, busy)
            return result
        },
        
        // router
        navigate: (routeName, routeParams, opts) => {
            console.log("navigating: ", routeName, routeParams, opts)
            router.navigate(routeName, routeParams, opts)
        },
        startRouter: (params) => {
            console.log("starting router...", routes)
            router.subscribe(({ route, previousRoute }) => {
                route = route || {}
                previousRoute = previousRoute || {}
                return Store.emit("ROUTE_CHANGED", {name: route.name, params: route.params, path: route.path, previous: {name: previousRoute.name, params: previousRoute.params, path: previousRoute.path}})
            })
            router.start()
        },
        
        // string
        removeEmptyProps: (obj) => {
          if(!obj) return {}
          for (var prop in obj) {
            if (typeof obj[prop] === 'object') {// dive deeper in
              window.CAFE.removeEmptyProps(obj[prop])
            } else if(obj[prop] === '') {// delete elements that are empty strings
              delete obj[prop]
            }
          }
          return obj
        },
        setEmptyPropsNull: (obj) => {
          if(!obj) return {}
          for (var prop in obj) {
            if (typeof obj[prop] === 'object') {// dive deeper in
              window.CAFE.removeEmptyProps(obj[prop])
            } else if(obj[prop] === '') {// delete elements that are empty strings
              obj[prop] = null
            }
          }
          return obj
        },
        setEmptyStringProps: (obj) => {
          if(!obj) return {}
          for (var prop in obj) {
            if (typeof obj[prop] === 'object') {// dive deeper in
              if(obj[prop] === null || obj[prop] === undefined)
                obj[prop] = ''
              else
                window.CAFE.setEmptyStringProps(obj[prop])
            } 
          }
          return obj
        },
        
        // main
        log: console.log
    }
}())


