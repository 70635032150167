import React, { Component } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import  moment from  'moment/moment';
import { Waypoint } from 'react-waypoint';


class MonthDetail extends Component {

    weekdayshort = moment.weekdaysShort();
    constructor(props) {
        super(props)
        var month = this.props.month;
        var year = this.props.year;
        var className = this.props.className;
        var monthName = moment().month(month).format("MMM");
        var id = [month,year];
        id = id.join('-');
        this.state = {
            dateObject: moment().year(year).month(month),
            'year' : year,
            'currentMonth'  : moment().month(),
            'currentYear'  : moment().year(),
            'month' : month,
            'today' : moment().date(),
            'monthName' : monthName,
            'className' : className,
            'redirect' : false,
            'date' : null,
            'id' : id
        }
        this.day = this.day .bind(this);

    }

    daysInMonth = () => {
        return this.state.dateObject.daysInMonth();
    };
    currentDay = () => {
        return this.state.dateObject.format("D");
    };

    firstDayOfMonth = () => {
        let dateObject = this.state.dateObject;
        let firstDay = moment(dateObject)
            .startOf("month")
            .format("d");
        return firstDay;
    };
    day(day){
        let month = this.state.month;
        let year = this.state.year;
      //console.log('day:',day,' month : ',month,' year:',year);
      let date = moment().year(year).month(month).date(day).format('DD-MM-YYYY');
        // console.log('date : ',date);
        this.setState({date: date});
        //this.setState({redirect: true});
        this.props.showWeek(date);
    }
    enter = () => {
        console.log('enter:',this.state.monthName,' ',this.state.year);
        this.props.yearOnDisplay(this.state.year);
    }
    
    getBookedEvents(){
        
        var expArr = [];
        if(this.props.createdEvents){
            this.props.createdEvents.map((event,index)=>{
                var endAt = moment(event.endAt);
                var startAt = moment(event.startAt);
                var totalDays = endAt.diff(startAt, 'days') + 1;
                
                if(totalDays > 0){    
                    for (var i = 0; i < totalDays; i++){  
                        const  bookedDate =moment(event.startAt).add(i,'days').format('YYYY-MM-DD')                  
                        expArr.push(bookedDate);
                    } 
                }
                else {
                    const bookedDate = event.startAt
                    expArr.push(bookedDate);
                }
            })
            let sortedEventsArray = expArr.sort((a, b) => {
            return a.eventDate - b.eventDate;
            
            });
            return sortedEventsArray;
        }
    }

    getStartDate(){
        const myArray = this.props.createdEvents;
        if(myArray){
        let myStartDate = myArray[0].startAt;
        for(let i = 0; i<myArray.length ; i++){
            if(myStartDate > myArray[i].startAt){
                myStartDate = myArray[i].startAt;
            }
        }
        return myStartDate;
    }}

    getEndDate(){
        const myArray = this.props.createdEvents;
        let myEndDate = myArray[0].endAt;
        for(let i = 0 ; i< myArray.length ; i++){
            if(myEndDate < myArray[i].endAt){
                myEndDate = myArray[i].endAt;
            }
        }
        return myEndDate;
    }
   
    
    render() {
        var startAt,endAt,firstEventStartDate,lastEventEndDate;
        if(this.props.createdEvents.length){

             startAt= this.getStartDate();
             endAt = this.getEndDate();
    
             firstEventStartDate =  moment(new Date(startAt)).format('YYYY-MM-DD');
             lastEventEndDate =  moment(new Date(endAt)).format('YYYY-MM-DD');
        }
        let checkMbl = this.props.mblDevice;
        const reservedDates = this.getBookedEvents();
        console.log('res',reservedDates);
       
        let weekdayshortname = this.weekdayshort.map(day => {
            return <th key={day}>{day}</th>;
        });
        let blanks = [];
        for (let i = 0; i < this.firstDayOfMonth(); i++) {
            blanks.push(<td  key={i} className={`${checkMbl ? 'mblCalendar-day' : 'calendar-day'} `}>{""}</td>);
        }

        let MonthPlace = [];
      //  MonthPlace.push(<tr className="month-scrollable">{""}</tr>);
        for (let i = 0; i < 7; i++) {
            if(i == this.firstDayOfMonth()){
                MonthPlace.push(<td key={i} className={`${checkMbl ?'mblMonth-scrollable' :'month-scrollable'}  ${(this.state.month == this.state.currentMonth && 
                    this.state.year == this.state.currentYear) ? 'month-current' : 'month'}`}>{this.state.monthName} {this.state.year}</td>);
                    // console.log('Montplace',MonthPlace);

                }else{
                MonthPlace.push(<td key={i} className={` empty ${checkMbl ?'mblMonth-scrollable mblCalendar-day' :'month-scrollable calendar-day'}`}>{""}</td>);
                //calendar-day empty
            }

        }
        var today = new Date();
        var year = moment.currentYear;
        var lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
        let daysInMonth = [];
        var foundDates = [];
        for (let d = 1; d <= this.daysInMonth(); d++) {
            let calDate =  moment(new Date(this.state.year,this.state.month,d)).format('YYYY-MM-DD');
            let showBlueBg = false;
            let coloredDate = false;

            if(calDate >= firstEventStartDate && calDate <= lastEventEndDate){
                showBlueBg = true;
            }

            if(reservedDates){
                for(var i= 0; i < reservedDates.length;i ++){
                    if(JSON.stringify( reservedDates[i]) ==  JSON.stringify(calDate)){
                        foundDates.push(calDate);
                        coloredDate = true;

                    }
                }
            
        }
            
            if (this.state.month == this.state.currentMonth && this.state.year == this.state.currentYear && this.state.today === d) {
               daysInMonth.push(
                    <td key={d} id={this.state.monthName}  className={`calendar-day-detail ${ showBlueBg ? 'tdBckgrnd' : ''}`} 
                     onClick={ () => this.day(d) }>
                        <span  className={'red'} >
                        <span className={ coloredDate ? 'circle' : ''}></span>

                            {d}
                            </span >
                    </td>
                    
                );
                
            }else            
            {
                daysInMonth.push(
                    //<Link  style={{ textDecoration: 'none' , color: 'black' , width : '85%' }}to={{pathname: "/Day", state: { monthName : this.state.monthName , year : this.state.year , day : d }}}>
                    <td key={d} id={this.state.monthName}  className={`calendar-day-detail
                    ${ showBlueBg ? 'tdBckgrnd' : ''}`} 
                    onClick={ () => this.day(d) }  >
                        <span className={ coloredDate ? 'circle' : ''}></span>
                        {d}
                    </td>
                   // </Link>
                );
            }   
        }

        var totalSlots = [...MonthPlace,...blanks, ...daysInMonth];
        let rows = [];
        let cells = [];

        totalSlots.forEach((row, i) => {
            if (i % 7 !== 0) {
                cells.push(row);
            } else {
                rows.push(cells);
                cells = [];
                cells.push(row);
            }
            if (i === totalSlots.length - 1) {
                // let insertRow = cells.slice();
                rows.push(cells);
            }
        });
        // console.log('count1',totalSlots);



        let daysinmonth = rows.map((d, i) => {
            // if(i == 1){
            //     return <tr className="month-scrollable">{d}</tr>;
            // }
            return <tr>{d}</tr>;
        });

        // if (this.state.redirect) {
        //
        //     return <Redirect push  to={{pathname: "/Day", state: { date : this.state.date }}}/>;
        // }
        return (
            // className={this.state.className}
            // id={this.state.monthName}
            
            

   <div  className="col-12 grid " style={{padding : '0px'}} id={this.state.id} month={this.state.monthName}  >
                <p>{this.state.day}</p>
                {/*<a id={this.state.monthName}>*/}
                  
            
              
                <Waypoint
                    onEnter={this.enter}
                   // onLeave={this._handleWaypointLeave}
                />
 

                    <table className=" full-width" >

                        <thead>
                        {/*<tr style={{}}>{weekdayshortname}</tr>*/}
                        {/*<tr className="month">{this.state.monthName}</tr>*/}
                        {/*<tr className={(this.state.month === this.state.currentMonth && this.state.year === this.state.currentYear) ? 'month-current' : 'month'}>{this.state.monthName}</tr>*/}
                        </thead>
                        {/*<Link  style={{ textDecoration: 'none' , color: 'black' }} to={{pathname: "/Day", state: { date : '2019-01-11' }}}>*/}
                        <tbody>{daysinmonth}</tbody>
                        {/*</Link>*/}

                    </table>
                    </div>  
        );
    }

}


export default MonthDetail;