import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import '../AppCalendar.css';


import  moment from  'moment/moment';
import Month from "./Month";
import MonthDetail from "./MonthDetail";

class Year extends Component {


    constructor(props) {
        super(props);
        this.state = {
            dateObject: moment().month(1),
            'year' : this.props.year,
            'currentYear' : moment().year(),
            'months' : [0,1,2,3,4,5,6,7,8,9,10,11],
            'className' : 'col-6 col-md-4 col-lg-3 flex-month',
            'detail' : false,
        }
        this.changeClass = this.changeClass .bind(this);
    }

    changeClass (){
        console.log('change');
        // const element = document.getElementsByClassName('col-sm-6 col-md-4 col-lg-3 flex-month');
        //console.log(element);
        if(this.state.className == 'col-sm-6 col-md-4 col-lg-3 flex-month transform') {
            this.setState({className: 'col-12 grid transform-active'});
        } else {
            this.setState({className: 'col-sm-6 col-md-4 col-lg-3 flex-month transform'})
        }
        const element = document.getElementById('Dec')
        // console.log(id);
        if (element) {
           // element.scrollIntoView()
        }
//col-12 grid
    }
    getStartDate(){
        const myArray = this.props.createdEvents;
        if(myArray.length === 0) return;
        let myStartDate = myArray[0].startAt;
        for(let i = 0; i<myArray.length ; i++){
            if(myStartDate > myArray[i].startAt){
                myStartDate = myArray[i].startAt;
            }
        }
        return myStartDate;
    }

    getEndDate(){
        const myArray = this.props.createdEvents;
        if(myArray.length === 0) return;
        let myEndDate = myArray[0].endAt;
        for(let i = 0 ; i< myArray.length ; i++){
            if(myEndDate < myArray[i].endAt){
                myEndDate = myArray[i].endAt;
            }
        }
        return myEndDate;
    }

    render() {
        const startAt = this.getStartDate();
        const endAt = this.getEndDate();

        const firstEventStartDate =  moment(new Date(startAt)).format('YYYY-MM-DD');
        const lastEventEndDate =  moment(new Date(endAt)).format('YYYY-MM-DD');
        let checkDev = this.props.mblDevice;

        return (

            // style={{width : '550px'}}
            <div>
                
            <div className={checkDev ? 'mblYear-scrollable' : 'year-scrollable'} style= {{borderBottom : '1px solid'}}>
                {/*<p>{this.state.currentYear}</p>*/}
                <div  className = "row" > 
                {/*<Link to={{pathname: "/YearDetail", state: { year: this.state.year }}}>*/}
                    {/*<p className = "year" id={this.state.year}>{this.state.year}</p>*/}
                {/*</Link>*/}
                
                    <span  style = {{borderBottom: '0px solid'}} className = {` col-sm-9 ${this.state.year != this.state.currentYear ? 'year':'year-current' }`} id={this.state.year}>{this.state.year}</span>
                </div>
                </div>
                {/*<hr/>*/}
                <div className="row" >
                    {this.state.months.map((item, key) =>
                    //<Link style={{ textDecoration: 'none' , color: 'black' }}>

                        <Month  month = {item} year ={this.state.year} className = {this.state.className}
                         yearDetail={this.props.yearDetail} firstEventStartDate= {firstEventStartDate} 
                         lastEventEndDate = {lastEventEndDate}
                         createdEvents={this.props.createdEvents}
                         mblDevice = {checkDev}/>
                   // </Link>
                )}
                </div>
           
            </div>
        );
    }

}


export default Year;