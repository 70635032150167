import React, { Component } from 'react';
import { DisplayAgenda } from '../components/displayAgenda/displayAgenda';
import { Link } from 'react-router-dom'
import '../App.css';
import  moment from  'moment/moment';
import back from './back.png'

class Agenda extends Component {


    constructor(props) {
        super(props);
        console.log("Agenda props props", this.props);

        
        var split = this.props.showDate.split("-");
        var monthName = moment(split[1], 'M').format('MMM');
        var year = moment(split[2], 'Y').format('Y');
        this.state = {
            'date' : this.props.showDate,
            'dateToDisplay' : monthName ,
            createdEvents : this.props.createdEvents,
            monthName,
            year

        }
        console.log(this.state.dateObject);
        console.log('Date',this.props.date);



    }

    componentDidMount() {
        // window.scrollTo(0, 0)
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.className!==this.props.className){
            
            this.setState({className: nextProps.className});
        }
    }

    toggleAgenda = () => {
        console.log('toggle agenda');
        this.setState({agenda: true})
    }



    getSortedEvents(){
        
        var expArr = [];
        console.log('startAt,createdEvents',this.props.createdEvents);
        this.props.createdEvents.map((event,index)=>{
            console.log("agenda days1 event:", event)
          var endAt = moment(event.endAt);
          var startAt = moment(event.startAt);
          var totalDays = endAt.diff(startAt, 'days') + 1;
         
          if(totalDays > 0){    
              for (var i = 0; i < totalDays; i++){  
                  const newEvent = {
                      ...event,
                      eventDate: moment(event.startAt).add(i,'days')                  }        
                  expArr.push(newEvent);
              } 
          }
          else {
              const newEvent = {
                  ...event,
                  eventDate: moment(event.startAt)              }
              expArr.push(newEvent);
          }
        })
  
        let sortedEventsArray = expArr.sort((a, b) => {
          return a.eventDate - b.eventDate;
          
        });
        console.log('agenda hix74128 sortedEventsArray',sortedEventsArray );
       
        return sortedEventsArray;
        
    }

    getFilteredEvents(){
        
        var split = this.props.showDate.split("-")
        const currentYear = parseInt(split[2] , 10 );
        const currentMonth = parseInt( split[1] , 10 );
        const sortedEvents = this.getSortedEvents();
        
        console.log('agenda currentMonth',currentMonth, " currentYear: ", currentYear);
        var filteredEvents = [];
        for(var i = 0 ; i < sortedEvents.length; i++){

            var eventYear = sortedEvents[i].eventDate.year();
            var eventMonth = sortedEvents[i].eventDate.month() + 1;
            if(eventYear === currentYear && eventMonth === currentMonth){
                filteredEvents.push(sortedEvents[i]);
            }
            console.log('agenda eventMonth', eventMonth, " eventYear: ", eventYear);

        }
        console.log('agenda filtered',filteredEvents);
        return filteredEvents;

    }
        
    renderFilteredEvents(filteredEvents){
        const {auth} = this.props;
        filteredEvents.map((event,index)=>{
            return(
                <DisplayAgenda  currentCal={this.props.currentCal}
                auth={auth}
                OAuthSignIn={this.props.OAuthSignIn} 
                handleShowSignInDialog={this.handleShowSignInDialog}
                fullScreen={this.props.fullScreen} key = {index} createdEvent = {event} 
                events={this.props.events}/>
            )
        })
    }
    
    renderYearHeader(){
        let  checkDev = this.props.mblDevice;

        console.log("days renderHeader ", this.state.year);

        return(
            <div className={checkDev ? "mblDay-scrollable" : "day-scrollable"}>
            <div className="row">
                <div className="col-2 col-md-2 col-lg-1 my-month" style={{color : 'red' ,display: 'flex',alignItems: 'flex-end'}} onClick={() => this.props.goToYearDetail(this.props)}>
                   <div><img src={back} alt="" style= {{height:'21px'}} /></div>
                   <div className="pointer" style={{fontSize:'medium'}}>{this.state.year}</div>
                </div>
               <div className="col-2 col-md-2 col-lg-2">    
               </div>
            </div>
        </div>
        )
    }

    renderHeader(){
     let checkDev = this.props.mblDevice;
        return(
            <div className={checkDev ? "mblDay-scrollable" : "day-scrollable"}>
            <div className="row">
                <div className="col-2 col-md-2 col-lg-1 my-month" style={{color : 'red' ,display: 'flex',alignItems: 'flex-end'}} onClick={() => this.props.goToYearDetail(this.props)}>
                   <div><img src={back} alt="" style= {{height:'21px'}} /></div>
                   <div className="pointer" style={{fontSize:'medium'}}>{this.props.monthName }&nbsp;{this.props.yearName }</div>
                </div>
               <div className="col-2 col-md-2 col-lg-2">    
               </div>
            </div>
        </div>
        )
    }
      
    handleShowSignInDialog = (props) => {
      this.props.handleShowSignInDialog(this.props);
    }

    render() {
        const {auth} = this.props;

        let displayAgendaItems = [];
        // const filteredEvents1 = this.getFilteredEvents();
        // const filteredEvents2 = this.getSortedEvents();
        const filteredEvents = this.props.createdEvents;

        filteredEvents.map((event,index)=>{

            let displayHeader = true;
            const prevEvent = filteredEvents[index-1];
            if(prevEvent && prevEvent.eventDate.format('LL') 
                            === event.eventDate.format('LL')){
                    displayHeader = false;
            }
            displayAgendaItems.push( 
                <DisplayAgenda  
                    key = {index} 
                    auth={auth}
                    OAuthSignIn={this.props.OAuthSignIn} 
                    handleShowSignInDialog={this.handleShowSignInDialog}
                    currentCal={this.props.currentCal}
                    fullScreen={this.props.fullScreen}
                    displayHeader={displayHeader}
                    createdEvent = {event} 
                    events={this.props.events}
                />
);
        });

        

        if(this.props.createdEvents){
            return (
                <div>
                    {this.renderHeader()}
                    {displayAgendaItems}
                </div>
            )
        }
        else{
            return(<DisplayAgenda
                    auth={auth}
                    OAuthSignIn={this.props.OAuthSignIn} 
                    handleShowSignInDialog={this.handleShowSignInDialog}
                 />)
        }
    }
}


export default Agenda;