import React, { Component } from 'react'
import { Formik } from "formik"
import * as Yup from "yup"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Switch from "@material-ui/core/Switch"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"
import FormLabel from "@material-ui/core/FormLabel"
import SnackbarContent  from "@material-ui/core/SnackbarContent"
import withStyles from "@material-ui/core/styles/withStyles"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from '@material-ui/core/InputLabel';
import { OutlinedInput } from '@material-ui/core';



const {CAFE} = window

const styles = theme => ({
    snackbarContent: {
        background: theme.palette.primary.dark,
        margin: theme.spacing.unit
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    container: {
        maxWidth: "500px"
    }
})


const validationSchema = Yup.object({
title: Yup.string("Enter a title for your calendar")
  .required("Title is required")
  .min(3, "Title must contain at least 2 characters")
  .max(80, "Title may contain at most 80 characters"),
description: Yup.string("Enter a description")
  .max(1000, "Description may contain at most 1000 characters"),
requireApprovalForOwner: Yup.boolean("Do you want to approve each owner?"),
requireApprovalForAdmin: Yup.boolean("Do you want to approve each admin?"),
requireApprovalForView: Yup.boolean("Do you want to have to approve each person that tries to view this calendar?")
})

const CreateCalForm = props => {
 const {
   values: { title, description,folderId, requireApprovalForOwner, requireApprovalForAdmin, requireApprovalForView },
   errors,
   touched,
   handleChange,
   handleSubmit,
   isSubmitting,
   isValid,
   setFieldTouched
 } = props

var folderLoc;
console.log('saved107',props.savedFolders)
 const change = (name, e) => {
   e.persist();
   handleChange(e);
   setFieldTouched(name, true, false);
 }

const letChange = (event) => {
  folderLoc = event.target.value;
}
 
 return (
    <form onSubmit={handleSubmit}>
        <TextField
            id="title"
            name="title"
            helperText={touched.title ? errors.title : ""}
            error={touched.title && Boolean(errors.title)}
            label="Title"
            value={title}
            onChange={change.bind(null, "title")}
            fullWidth
            margin="normal"
            variant="outlined"
        />
        <TextField
            id="description"
            name="description"
            multiline
            helperText={touched.description ? errors.description : ""}
            error={touched.description && Boolean(errors.description)}
            label="Description"
            fullWidth
            value={description}
            onChange={change.bind(null, "description")}
            margin="normal"
            variant="outlined"
        />
        
        <InputLabel htmlFor="age-simple">Select Folder</InputLabel>
        <Select 
        id={folderId}
        name="folderId"
        label = 'Select Folder'
        value={folderId}
        onChange={change.bind(null,'folderId')}
        fullWidth 
        displayEmpty
        input={<OutlinedInput/>}
        variant = 'outlined'
        
        >
          {/* <MenuItem value = {this.props.savedFolders.map((folder)=>{
            folder
          })}></MenuItem> */}
          
          {props.savedFolders.map((folder,index)=>{
            return(
            <MenuItem key={index} value={folder.id} >
            {folder.title}
            </MenuItem>
            )
          })}
        </Select>

        <FormControl component="fieldset" style={{display: "block"}} margin="normal">
            <FormLabel component="legend">Approval</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    id='requireApprovalForOwner' 
                    checked={requireApprovalForOwner}
                    onChange={change.bind(null, "requireApprovalForOwner")}
                    value="gilad"
                  />
                }
                label="Do you want to approve each owner?"
              />
              <FormControlLabel
                control={
                  <Switch
                    id='requireApprovalForAdmin' 
                    checked={requireApprovalForAdmin}
                    onChange={change.bind(null, "requireApprovalForAdmin")}
                    value="gilad"
                  />
                }
                label="Do you want to approve each admin?"
              />
              <FormControlLabel
                control={
                  <Switch
                    id='requireApprovalForView' 
                    checked={requireApprovalForView}
                    onChange={change.bind(null, "requireApprovalForView")}
                    value="gilad"
                  />
                }
                label="Do you want to have to approve each person that tries to view this calendar?"
              />
            </FormGroup>
        </FormControl>
        <Button
            margin="normal"
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid || isSubmitting}
            style={{minWidth: "100px"}}
        >
            Save
        </Button>
    </form>
 )
}



class CreateCal extends Component {

    handleSignInButton = () => {
        if(this.props.OAuthSignIn) this.props.OAuthSignIn()
    }
     

    render() {
        const me = this
        const props = me.props || {}
        const {auth, classes, savedFolders} = props
        const accountId = auth && auth.account ? auth.account.id : false
        
        console.log("create cal savedFolders:", savedFolders);

        if(!accountId) return ( <SnackbarContent className={classes.snackbarContent}
          message={<span id="message-id">You must be signed in to create a calendar!</span>}
          action={[
            <Button key="signin" color="secondary" size="large" onClick={me.handleSignInButton}>
              Sign In or Sign Up
            </Button>
          ]}
        /> )
        
        const values = { title: "", description: "", folderId : "",  requireApprovalForOwner: true, requireApprovalForAdmin: false, requireApprovalForView: false }
        return (
            <React.Fragment>
                <div className={classes.container}>
                    <h1>Create a new calendar</h1>
                    <Formik
                        render={props => <CreateCalForm {...props} savedFolders={this.props.savedFolders} />}
                        initialValues={values}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                                CAFE.trigger("CREATE_CAL", {
                                    ...values,
                                    accountId: accountId,
                                    onError: (result) => { console.log("CREATE CAL ERROR:", result); alert("ERROR"); setSubmitting(false) },
                                    onSuccess: (result) => { 
                                        console.log("CREATE CAL SUCCESS:", result)
                                        CAFE.trigger("FETCH_SAVEDCALS", {accountId:accountId})
                                        setSubmitting(false)
                                        CAFE.navigate("CAL", {calId: result.cal.id, key: result.accessKeys}) }
                                })
                            }, 400);
                        }}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(CreateCal)
