import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import '../App.css';
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import back from './back.png'
import  moment from  'moment/moment';
import Month from "./Month";
import DayDetail from "./DayDetail";
import Agenda from "./Agenda";
import { parse } from 'path';
import TagChip from '../dash/tagChip.js';

const alldays = "All Days"

class Day extends Component {

    weekdayshort = moment.weekdaysShort();

    constructor(props) {
        super(props);
        console.log("Day constructor this props:", this.props);
        var split = this.props.showDate.split("-")
        const curMonth = parseInt( split[1] , 10 );
        const curYear = parseInt(moment(split[2], 'Y').format('YYYY'));
        var year = moment(split[2], 'Y').format('Y');
        var initElementWithId = [curMonth,year];
        initElementWithId = initElementWithId.join('-');

        let dayItems = [];

        this.state = {
            selectedTags: [alldays],
            'date' : this.props.date,
            dateObject: moment(this.props.date,"YYYY-MM-DD").dayOfYear(),
            'weekday' :moment(this.props.date,"YYYY-MM-DD").weekday(),
            'days':  moment().dayOfYear() ,
            'monthName' : moment(split[1], 'M').format('MMMM'),
            year : moment(split[2], 'Y').format('YYYY'),
            'dayNo':'',
            'months' : [0,1,2,3,4,5,6,7,8,9,10,11],
            'agenda' :true,
            prev: true,
            curMonthShown: curMonth,
            curYearShown: curYear,
            elemenId: initElementWithId,
            baseElementId: initElementWithId,
            originalElementId: initElementWithId,
            displayDayItems: dayItems,
            calEvents: this.props.createdEvents
            // 'className' : 'display-inline-flex',
        }
    }

    daysInMonth (month, year) {
        return new Date(year, month, 0).getDate();
    }

    daysInMonth1 = () => {
        return this.state.dateObject.daysInMonth();
    };

    dayNameInYear = () => {
        let day = this.state.dayNo;
        return moment().year(2019).dayOfYear(day).format("ddd");
    }
    dayDate = () => {
        let day = this.state.dayNo;
        return moment().year(2019).dayOfYear(day).format("DD");
    }
    dayMonth = () => {
        let day = this.state.dayNo;
        return moment().year(2019).dayOfYear(day).format("MMM");
    }

    firstDayOfMonth = () => {
        let dateObject = this.state.dateObject;
        return dateObject;
        // let firstDay = moment(dateObject,"DD-MM-YYYY")
        //     .startOf("month")
        //     .format("d");
        // return firstDay;
    };

    componentWillMount() {
        
        this.reloadAgendaItems();

        let baseMonth = this.state.curMonthShown;
        let baseYear = this.state.curYearShown;
        if(baseMonth <= 1){
            baseYear--;
            baseMonth = 12;
        } else {
            // baseMonth--;
        }

        this.addTopAgendaItems(baseMonth, baseYear);

    }

    componentDidMount() {
        console.log('days1 componentDidMount new days currentMonth',this.state.curMonthShown); 

        // window.scrollTo(0, 0);
        // window.addEventListener('scroll', this.trackScrolling, false);

        const element = document.getElementById(this.state.originalElementId);
        console.log("this.state.baseElementId", this.state.originalElementId);
        if (element) {
            element.scrollIntoView();
            console.log('dde');
            console.log('baseElementId displayDayItems', this.state.displayDayItems);
        }

        // this.add TopAgendaItems();
        // this.add TopAgendaItems();

    }
    componentWillUnmount() {
        console.log('new days component Will Un mount'); 
        // window.removeEventListener('scroll', this.trackScrolling, false);
    }
    componentWillReceiveProps(nextProps){
        console.log("day componentWillReceiveProps nextProps:", nextProps);
        if(nextProps.createdEvents !== this.props.createdEvents){
            console.log("day componentWillReceiveProps !==");
            this.setState({
                calEvents: nextProps.createdEvents
            });
            this.reloadAgendaItems();
        }
    }

    handleSelectTag = (tag) => {
        console.log("handleSelectTag", tag);
      let updatedTags = this.state.selectedTags;
      const pos = updatedTags.indexOf(tag)
      if(pos > -1) updatedTags.splice(pos,1)
      else updatedTags.push(tag)
      this.setState({selectedTags: updatedTags})

      console.log("handleSelectTag updatedTags", updatedTags);
    }

    getSortedEvents(){
        const eventsList =  this.state.calEvents;
        console.log('startAt, eventsList', eventsList);

        var hashMap = {};
        eventsList.map((event,index)=>{
            var endAt = moment(event.endAt);
            var startAt = moment(event.startAt);
            var totalDays = endAt.diff(startAt, 'days') + 1;
            
            if(totalDays > 0){    
              for (var i = 0; i < totalDays; i++){  
                  const newEvent = {
                      ...event,
                      eventDate: moment(event.startAt).add(i,'days').startOf('day')  
                    }       
                const key = newEvent.eventDate;
                if (key in hashMap)
                    hashMap[key].push(newEvent);
                else
                    hashMap[key] = [ newEvent ];                    
              } 
            }
            else {
              const newEvent = {
                  ...event,
                  eventDate: moment(event.startAt).startOf('day')            
                }
              const key = newEvent.eventDate;
              if (key in hashMap)
                  hashMap[key].push(newEvent);
              else
                  hashMap[key] = [ newEvent ];    
            }               
        })
        // Create array from dictionary
        var hashMapList = Object.keys(hashMap).map(function(key) {
            let sortedValuesList = hashMap[key].sort((a, b) => {
                return b.isAllDay - a.isAllDay;

            });
            return sortedValuesList;
        });
        var sortedEventsArray = [];
        hashMapList.map((item,index)=>{
            item.map((event,index)=>{
                sortedEventsArray.push(event);
            });
        });
        return sortedEventsArray;
        
    }

    getTopFilteredEvents(currentMonth, currentYear){
        
        console.log('getTopFiltered Events new days currentMonth',currentMonth, " currentYear: ", currentYear); 
        
        var updatedFilteredEvents = [];
        const sortedEvents = this.getSortedEvents();
        console.log("top days1 In sortedEvents:", sortedEvents);

        const daysInMonth = this.daysInMonth(currentMonth, currentYear);  
        console.log("top days1 In Month:", daysInMonth);

        const monthToIterate = currentMonth - 1;
        for(let i = 1; i <= daysInMonth; i++) {
            console.log("top days1 date To Check1:", i);
            const dateToCheck = moment(new Date(currentYear, monthToIterate, i));
            console.log("top days1 date To Check1:", dateToCheck.date());   
            let foundEvent = null;
            for(var j = 0 ; j < sortedEvents.length; j++){
                const event = sortedEvents[j];
                var eventYear = sortedEvents[j].eventDate.year();
                var eventMonth = sortedEvents[j].eventDate.month() + 1;
                const eventDay = moment(event.startAt).date();

                if(eventYear === currentYear && eventMonth === currentMonth
                    && eventDay === i){
                    foundEvent = sortedEvents[j];
                    console.log('top new days1 event Day:', 
                        eventDay,'new days event Month:', 
                        eventMonth, " eventYear: ", eventYear, 
                        "top  event startAt: ", event.startAt, 
                        " sortedEvents[j]: ", sortedEvents[j]); 
                }
            }
            if(foundEvent === null){
                updatedFilteredEvents.push({
                    eventDate: new Date(currentYear, monthToIterate, i)
                });
            } else {
                updatedFilteredEvents.push(foundEvent);
            }
        }
        console.log('top new days1 filtered',updatedFilteredEvents);
        return updatedFilteredEvents;
    }

    getAllDays(offsetMonthVal=0, offsetYearVal=0){
        
        let currentMonth = this.state.curMonthShown - offsetMonthVal;
        let currentYear = this.state.curYearShown - offsetYearVal;
        console.log('new days currentMonth',currentMonth, " currentYear: ", currentYear); 
        
        var updatedFilteredEvents = [];
        const sortedEvents = this.getSortedEvents();
        console.log("days1 In sortedEvents:", sortedEvents);

        const daysInMonth = this.daysInMonth(currentMonth, currentYear);  
        console.log("days1 In Month:", daysInMonth);

        const monthToIterate = currentMonth - 1;
        for(let i = 1; i <= daysInMonth; i++) {
            console.log("days1 date To Check1:", i);
            const dateToCheck = moment(new Date(currentYear, monthToIterate, i));
            console.log("days1 date To Check1:", dateToCheck.date());
            
            // updatedFilteredEvents.push({
                 
            // });
            let foundEvent = null;
            for(var j = 0 ; j < sortedEvents.length; j++){

                const event = sortedEvents[j];


                var eventYear = sortedEvents[j].eventDate.year();
                var eventMonth = sortedEvents[j].eventDate.month() + 1;
                const eventDay = moment(event.startAt).date();

                if(eventYear === currentYear && eventMonth === currentMonth
                    && eventDay === i){
                    // updatedFilteredEvents.pop();
                    // updatedFilteredEvents.push(sortedEvents[j]);
                    foundEvent = sortedEvents[j];

                    console.log('new days1 event Day:', 
                        eventDay,'new days event Month:', 
                        eventMonth, " eventYear: ", eventYear, 
                        " event startAt: ", event.startAt, 
                        " sortedEvents[j]: ", sortedEvents[j]); 
                }else{
                    // console.log('new days1 event Day:', 
                    // eventDay,'new days event Month:', 
                    // eventMonth, " eventYear: ", eventYear); 
                }

            }

            if(foundEvent === null){
                updatedFilteredEvents.push({
                    eventDate: new Date(currentYear, monthToIterate, i)
                    // ,
                    // startAt: new Date(currentYear, monthToIterate, i),
                    // endAt: dateToCheck 
                });
            } else {
                updatedFilteredEvents.push(foundEvent);
            }
        }
        console.log('new days1 filtered',updatedFilteredEvents);
        return updatedFilteredEvents;

    }

    filterDaysWithEvents(){
        
        const currentMonth = this.state.curMonthShown;
        const currentYear = this.state.curYearShown;
        console.log('new days currentMonth',currentMonth, " currentYear: ", currentYear); 
        
        var filteredEvents = [];
        const sortedEvents = this.getSortedEvents();
        for(var i = 0 ; i < sortedEvents.length; i++){

            var eventYear = sortedEvents[i].eventDate.year();
            var eventMonth = sortedEvents[i].eventDate.month() + 1;
            if(eventYear === currentYear && eventMonth === currentMonth){
                filteredEvents.push(sortedEvents[i]);
            }
            console.log('new days eventMonth', eventMonth, " eventYear: ", eventYear); 

        }
        console.log('new days filtered',filteredEvents);
        return filteredEvents;

    }
    
    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    unShiftAgendaItems(){


        var inViewport = require('in-viewport');

        const baseElementId = this.state.baseElementId;
        const baseElement = document.getElementById(this.state.baseElementId);
        var baseElementIsInViewport = inViewport(baseElement);
        console.log("day day baseElementIsInViewport", baseElementIsInViewport);
        if (baseElementIsInViewport && this.state.prev === false){
            var splitBaseElementId = baseElementId.split("-");

            if(splitBaseElementId.length >=2 ){
                let baseMonth  = parseInt( splitBaseElementId[0]);
                let baseYear   = parseInt( splitBaseElementId[1]);
                console.log("day day state elemen Id1: ", this.state.elemenId);
      
                if(baseMonth <= 1){
                    baseYear--;
                    baseMonth = 12;
                } else {
                    // baseMonth--;
                }

                this.setState({
                    prev: true
                });
                const oldBaseElementId = this.state.baseElementId;

                this.addTopAgendaItems(baseMonth, baseYear);

                const element = document.getElementById(oldBaseElementId);
                console.log("oldBaseElementId", oldBaseElementId);
                console.log("oldBaseElementId new", oldBaseElementId);
                if (element) {
                    // element.scrollIntoView({behavior: 'smooth'});
                    element.scrollIntoView();
                }
            }
        }
    }

    reloadAgendaItems(){
        return;
        let dayItems = this.state.displayDayItems;
        const isTagSelected = this.state.selectedTags.indexOf(alldays) > -1;
        const filteredEvents = (isTagSelected) ?this.getAllDays() :this.filterDaysWithEvents();
        const currentMonth = this.state.curMonthShown;
        const currentYear = this.state.year;
        const daysInMonth = this.daysInMonth(currentMonth, currentYear);  
        console.log("days1 In Month:", daysInMonth);
        var monthName = moment(currentMonth, 'M').format('MMM');
        // const currentYear= this.state.curYearShown;
        var yearName = moment(currentYear, 'Y').format('YYYY');

        var initElementWithId = [this.state.curMonthShown, this.state.curYearShown];
        initElementWithId = initElementWithId.join('-');
        this.setState({
            elemenId: initElementWithId
        });        
        dayItems.push(
            <div id={this.state.elemenId}>
                <Agenda 
                    key={this.state.elemenId} 
                    id={this.state.elemenId} 
                    createdEvents={filteredEvents} 
                    showDate = {this.props.showDate}
                    monthName = {monthName}
                    yearName = {yearName}
                    goToYearDetail={this.goToYearDetail}
                    currentCal={this.props.currentCal}
                    fullScreen={this.props.fullScreen}
                    events={this.props.events} 

                />
            </div>
        );
        this.setState({
            displayDayItems: dayItems
        });
    }
    addTopAgendaItems(firstMonth, firstYear) {
        return;
        if (this.state.prev){ 
            
            const fetchMonth = firstMonth - 1;
            const fetchYear = firstYear;

            const isTagSelected = this.state.selectedTags.indexOf(alldays) > -1;
            const filteredEvents = (isTagSelected) ?this.getTopFilteredEvents(fetchMonth, fetchYear) :this.filterDaysWithEvents();
            if(filteredEvents.length){
                let dayItems = this.state.displayDayItems;
                const currentMonth = fetchMonth;
                const currentYear= fetchYear;
                var monthName = moment(currentMonth, 'M').format('MMM');
                var yearName = moment(currentYear, 'Y').format('YYYY');

                var initElementWithId = [currentMonth, currentYear];
                initElementWithId = initElementWithId.join('-');

                dayItems.unshift(
                    <div id={initElementWithId}>
                        <Agenda createdEvents={filteredEvents} 
                            key={this.state.elemenId} 
                            id={initElementWithId} 
                            showDate = {this.props.showDate}
                            monthName = {monthName}
                            yearName = {yearName}
                            goToYearDetail={this.goToYearDetail}
                            currentCal={this.props.currentCal}
                            fullScreen={this.props.fullScreen}
                            events={this.props.events} 
        
                        />
                    </div>
                );

                console.log("baseElementId ", this.state.baseElementId);
                console.log("new baseElementId ", initElementWithId);

                this.setState({
                    displayDayItems: dayItems,
                    baseElementId: initElementWithId,
                    prev: false
                });
        
            }else{
                this.setState({
                    prev: false
                });
            }
        }
    }
    addBottomAgendaItems() {
        return;
        if (this.state.next){        
            const isTagSelected = this.state.selectedTags.indexOf(alldays) > -1;
            const filteredEvents = (isTagSelected) ?this.getAllDays() :this.filterDaysWithEvents();
            if(true || filteredEvents.length){
                let dayItems = this.state.displayDayItems;
                const currentMonth = this.state.curMonthShown;
                const currentYear= this.state.curYearShown;
                var monthName = moment(currentMonth, 'M').format('MMM');
                var yearName = moment(currentYear, 'Y').format('YYYY');


                var initElementWithId = [currentMonth, this.state.curYearShown];
                initElementWithId = initElementWithId.join('-');
                this.setState({
                    elemenId: initElementWithId
                });

                dayItems.push(
                    <div id={this.state.elemenId}>
                        <Agenda createdEvents={filteredEvents} 
                            key={this.state.elemenId} 
                            id={this.state.elemenId} 
                            showDate = {this.props.showDate}
                            monthName = {monthName}
                            yearName = {yearName}
                            goToYearDetail={this.goToYearDetail}
                            currentCal={this.props.currentCal}
                            fullScreen={this.props.fullScreen}
                            events={this.props.events} 
        
                        />
                    </div>
                );
                this.setState({
                    displayDayItems: dayItems,
                    next: false
                });
            }else{
                this.setState({
                    next: false
                });
            }
        }
    }

    trackScrolling = () => {
        

      if(window.pageYOffset === 0) {

        this.unShiftAgendaItems();

          console.log("display Day Items: ", this.state.displayDayItems[0].props.id);
          const elementId = this.state.displayDayItems[0].props.id;
          var split = elementId.split("-");
          if(split.length >=2 ){
              const firstMonth  = parseInt( split[0]);
              const firstYear   = parseInt( split[1]);
              console.log("day day state elemen Id1: ", this.state.elemenId);
    
            //   this.add TopAgendaItems(firstMonth, firstYear);
          }   
          return;
      }

        console.log("day day state elemen Id2: ", this.state.elemenId);
        console.log('agenda next', this.state.next);

        const wrappedElement = document.getElementById(this.state.elemenId);
        var inViewport = require('in-viewport');
        var isInViewport = inViewport(wrappedElement);
        console.log("day day isInViewport", isInViewport);
        if (isInViewport){
            let updatedCurMonth = this.state.curMonthShown;
            let updatedCurYear = this.state.curYearShown;
            if(updatedCurMonth >= 12){
                updatedCurYear++
                updatedCurMonth = 1;
            } else {
                updatedCurMonth++;
            }
            this.setState({
                next: true,
                curMonthShown: updatedCurMonth,
                curYearShown: updatedCurYear
            });
            console.log('days is In Viewport true');
            this.addBottomAgendaItems();
        }
        console.log(this.state.next);
        
    }


    toggleAgenda = () => {
        console.log('toggle agenda');
        if (this.state.agenda == true){
            this.setState({agenda: false})
        }else {
            this.setState({agenda: true})
        }

    }
    

    goToYearDetail = (props) => {
        this.props.goToYearDetail(this.props);
    }

    oldrender1() {
    
        let weekdayshortname = this.weekdayshort.map(day => {
            return <th key={day}>{day}</th>;
        });

        let days = [];
            // this.daysInMonth();
        let d = this.state.dateObject;
        console.log(d);
        let w = this.state.weekday;
        console.log(this.state.weekday);
        let selected = d;
        d= d- w;
        // d =moment().day(-7).format('YYYY-MM-DD');
        let final = d+100;
        for (d ; d <= final; d++ ){
            this.state.dayNo =d;
            days.push(
                <td className=" ">
                    {/*<p>{this.dayMonth()}</p>*/}
                    <p className="margin-bottom">{this.dayNameInYear()}</p>
                    <p className="margin-bottom">{this.dayDate()}</p>
                </td>
            );
            // days = d;
        }

        var totalSlots = [...days];
        let rows = [];
        let cells = [];

        totalSlots.forEach((row, i) => {
            if (i % 7 !== 0) {
                cells.push(row);
            } else {
                rows.push(cells);
                cells = [];
                cells.push(row);
            }
            if (i === totalSlots.length - 1) {
                // let insertRow = cells.slice();
                rows.push(cells);
            }
        });

        let week = rows.map((d, i) => {
            console.log('d',d);
            return <tr className="col-12 week-scroll">{d}</tr>;

        });
        week.shift();   

        let dayItems = this.state.displayDayItems;
        console.log("new day day Items: ", dayItems);

        return (

            <div id = {this.state.elemenId}>
                {/* {!this.state.agenda && (
                    <DayDetail />
                )} */}
             
                {!this.state.agenda || (dayItems
                )}

            </div>
        );
    }
      
    handleShowSignInDialog = (props) => {
      this.props.handleShowSignInDialog(this.props);
    }

    renderHeader(){
      let  checkDev = this.props.mblDevice;
        console.log("checkMbl", this.props.mblDevice);

        return(
            <div className= {checkDev ? "mblDay-scrollable" : "day-scrollable"}>
            <div className="row">
                <div className="col-2 col-md-2 col-lg-1 my-month" style={{color : 'red' ,display: 'flex',alignItems: 'flex-end'}} onClick={() => this.props.goToYearDetail(this.props)}>
                   <div><img src={back} alt="" style= {{height:'21px'}} /></div>
                   <div className="pointer" style={{fontSize:'medium'}}>{this.state.year}</div>
                </div>
               <div className="col-2 col-md-2 col-lg-2">    
               </div>
            </div>
            </div>
        )
    }
    render() {

        const {auth} = this.props;


        let dayItems = [];
        const filteredEvents = this.filterDaysWithEvents();
        const currentMonth = this.state.curMonthShown;
        const currentYear = this.state.year;
        const daysInMonth = this.daysInMonth(currentMonth, currentYear);  
        console.log("days1 In Month:", daysInMonth);
        var monthName = moment(currentMonth, 'M').format('MMM');
        var yearName = moment(currentYear, 'Y').format('YYYY');

              
        dayItems.push(
            <div id={this.state.elemenId}>
                <Agenda 
                    createdEvents={filteredEvents} 
                    auth={auth}
                    OAuthSignIn={this.props.OAuthSignIn} 
                    handleShowSignInDialog={this.handleShowSignInDialog}
                    showDate = {this.props.showDate}
                    monthName = {monthName}
                    yearName = {yearName}
                    goToYearDetail={this.goToYearDetail}
                    currentCal={this.props.currentCal}
                    fullScreen={this.props.fullScreen}
                    events={this.props.events} 
                    mblDevice={this.props.mblDevice}

                />
            </div>
        );


        // let dayItems = this.state.displayDayItems;
        console.log("days day day Items: ", dayItems, " year:", this.state.year);
        console.log("days agenda ", this.state.agenda, " elementid:", this.state.elemenId);
       
        const isTagSelected = this.state.selectedTags.indexOf(alldays) > -1;
        console.log("isTagSelected: ", isTagSelected);

        return (
            <div>
                {/* <TagChip key={alldays} selected={isTagSelected} tag={alldays} handleSelectTag={this.handleSelectTag} /> */}

                 {/*{this.renderHeader()}*/}
                {!this.state.agenda || (dayItems
                )}

            </div>
        );
    }

}


export default Day;